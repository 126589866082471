import styled from 'styled-components';
import { color, spacing, value, font } from '../../../../assets/styles';
import { RadioLabel } from './CompanyRadio';

export const FieldContainer = styled.fieldset`
  display: block;
  margin: 0 0 ${spacing.medium};
  padding: 0;
  border: none;
  width: 100%;
  max-width: 480px;
`;

export const Legend = styled.legend`
  display: block;
  margin: 0 0 ${spacing.xSmall};
  padding: 0;
  text-align: left;
  font-size: ${font.smallPlus};
  color: ${color.darkGray};
`;

const borderWidth = '1px';
export const FlexContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  box-sizing: border-box;
  border: ${borderWidth} solid ${color.lightGray};
  border-radius: ${value.rounding};
  background-color: ${color.white};
  transition: border-color 0.3s ease;
  &:focus-within {
    border-color: ${color.ice};
  }
`;
export const FlexItem = styled.div`
  flex: 1 0 ${borderWidth};
  height: 4rem;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 0.5rem; // TODO: remove
  & + & ${RadioLabel} {
    border-left: ${borderWidth} solid ${color.lightGray};
    flex-basis: 0;
  }
`;

export const TabContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  box-sizing: border-box;
  border: ${borderWidth} solid ${color.lightGray};
  border-top-left-radius: ${value.rounding};
  border-top-right-radius: ${value.rounding};
  border-bottom: none;
  background-color: ${color.white};
  transition: border-color 0.3s ease;
  &:focus-within {
  }
`;

export const AccessWithoutLoginContainer = styled.div`
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const TabTextContainer = styled.div`
  display: block;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 35px;
  padding-right: 35px;
`;
