import { StatelessComponent } from 'react';
import * as React from 'react';
import styled from 'styled-components';
import { spacing } from '../../assets/styles';

const ICONS = {
  arrowRight: require('./arrow-right.svg'),
  chevronDown: require('./chevron-down.svg'),
  cookieConsentIcon: require('./online-block.svg'),
  exportDataIcon: require('./print-bag-tag-block.svg'),
  marketingConsentIcon: require('./e-mail.svg'),
  checkList: require('./checklist-block.svg'),
  holdMyBooking: require('./hold-my-booking-block.svg'),
  onlineChecked: require('./online-checked-block.svg'),
  plusSymbol: require('./tier-points-block.svg'),
  addSymbol: require('./add-badge.svg'),
  checkMarkBlock: require('./check-mark-block.svg'),
  selected: require('./selected.svg'),
  unselected: require('./unselected.svg'),
  help: require('./help.svg'),
  printBagTag: require('./print-bag-tag.svg'),
  info: require('./info.svg'),
};

type RequiredProps = {
  name: keyof typeof ICONS;
};
type OptionalProps = {
  size: keyof typeof spacing;
  rotate180: boolean;
  display: string;
  margin: string;
};
type IconProps = RequiredProps & Partial<OptionalProps>;
type IconSpanProps = RequiredProps & OptionalProps;

const IconSpan = styled.span<IconSpanProps>`
  display: ${(props: IconSpanProps) => props.display};
  height: ${(props: IconSpanProps) => spacing[props.size]};
  width: ${(props: IconSpanProps) => spacing[props.size]};
  margin: ${(props: IconSpanProps) => props.margin};
  box-sizing: border-box;
  background-image: url('${(props: IconProps) => ICONS[props.name]}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  transition: 0.3s transform ease;
  transform: ${({ rotate180 }) => (rotate180 ? 'rotate(-180deg)' : 'rotate(0deg)')};
`;

export const Icon: StatelessComponent<IconProps> = ({
  name,
  size,
  rotate180 = false,
  display = 'block',
  margin = 'auto',
}) => <IconSpan name={name} size={size || 'xLarge'} rotate180={rotate180} display={display} margin={margin} />;
