import * as React from 'react';
import { PureComponent } from 'react';
import { Ingress, MobileIngress, PageTitle } from '../../common/type';
import { HeroBgImage, HeroContainer, ContentTitle, HeroContentWrapper } from './view';

type Props = {
  title: string;
  ingress: string;
};
export default class HeroImage extends PureComponent<Props> {
  render() {
    const { title, ingress } = this.props;
    return (
      <HeroContainer>
        <HeroBgImage>
          <HeroContentWrapper>
            <ContentTitle>
              <PageTitle>{title}</PageTitle>
              <Ingress dangerouslySetInnerHTML={{ __html: ingress }} />
            </ContentTitle>
          </HeroContentWrapper>
        </HeroBgImage>
        <MobileIngress dangerouslySetInnerHTML={{ __html: ingress }} />
      </HeroContainer>
    );
  }
}
