import { Action } from '../../common/reducer-utils';
import { Dispatch } from 'redux';
import { RootState } from '../../app/state.interface';
import { ThunkAction } from 'redux-thunk';
import { CONSENT_STATUS, ReturnedConsents } from '../../snippet/cookie-snippet.interface';
import ConsentItem = Components.Schemas.ConsentItem;
import { signalLoadedConsents, signalUpdatedConsents } from '../../dataLayer';

export const TOGGLE_COOKIE_CONSENTS = 'TOGGLE_COOKIE_CONSENT';
export const TOGGLE_ALL_COOKIE_CONSENTS = 'TOGGLE_ALL_COOKIE_CONSENTS';
export const FETCH_COOKIE_CONSENT_INIT_DATA_START = 'FETCH_COOKIE_CONSENT_INIT_DATA_START';
export const FETCH_COOKIE_CONSENT_INIT_DATA_SUCCESS = 'FETCH_COOKIE_CONSENT_INIT_DATA_SUCCESS';
export const SET_CHECKED_COOKIE_CONSENT_START = 'SET_CHECKED_COOKIE_CONSENT_START';
export const SET_CHECKED_COOKIE_CONSENT_SUCCESS = 'SET_CHECKED_COOKIE_CONSENT_SUCCESS';
export const SET_CHECKED_COOKIE_CONSENT_FAIL = 'SET_CHECKED_COOKIE_CONSENT_FAIL';

export type CookieConsentsActionFailTypes = typeof SET_CHECKED_COOKIE_CONSENT_FAIL;

export type CookieConsentsActionTypes =
  | typeof TOGGLE_COOKIE_CONSENTS
  | typeof TOGGLE_ALL_COOKIE_CONSENTS
  | typeof FETCH_COOKIE_CONSENT_INIT_DATA_START
  | typeof FETCH_COOKIE_CONSENT_INIT_DATA_SUCCESS
  | typeof SET_CHECKED_COOKIE_CONSENT_START
  | typeof SET_CHECKED_COOKIE_CONSENT_SUCCESS
  | CookieConsentsActionFailTypes;

export function handleToggleCookieConsents(itemId: string): ToggleCookieConsentAction {
  return {
    type: TOGGLE_COOKIE_CONSENTS,
    payload: { itemId },
  };
}

export const handleToggleAllCookieConsents = (): handleToggleAllCookieConsentsAction => ({
  type: TOGGLE_ALL_COOKIE_CONSENTS,
  payload: undefined,
});

export const fetchConsentInitDataStart = (): FetchConsentInitDataStartAction => ({
  type: FETCH_COOKIE_CONSENT_INIT_DATA_START,
  payload: undefined,
});

export const fetchConsentInitDataSuccess = (cookieConsents: ReturnedConsents): FetchConsentInitDataSuccessAction => ({
  type: FETCH_COOKIE_CONSENT_INIT_DATA_SUCCESS,
  payload: cookieConsents,
});

type CookieConsentAction = ThunkAction<Promise<void>, RootState, void, Action<any>>;

export function createFetchDataFromBackendAction(): CookieConsentAction {
  return async (dispatch: Dispatch<any>, _getState: () => RootState) => {
    dispatch(fetchConsentInitDataStart());
    if (window.finnairCookieSnippet) {
      window.finnairCookieSnippet.getConsents((consentGroup: ReturnedConsents) => {
        const googleAnalyticsAData = consentGroup.consents.reduce((acc, consent) => {
          return {
            ...acc,
            [consent.consentTextId]: consent.consentStatus === CONSENT_STATUS.accepted,
          };
        }, {});
        signalLoadedConsents(googleAnalyticsAData);
        dispatch(fetchConsentInitDataSuccess(consentGroup));
      });
    }
  };
}

export const setCheckedCookieConsentStart = (): SetCheckedCookieConsentStartAction => ({
  type: SET_CHECKED_COOKIE_CONSENT_START,
  payload: undefined,
});

export const setCheckedCookieConsentSuccess = (): SetCheckedCookieConsentSuccessAction => ({
  type: SET_CHECKED_COOKIE_CONSENT_SUCCESS,
  payload: undefined,
});

export const setCheckedCookieConsentFail = (): SetCheckedCookieConsentFailAction => ({
  type: SET_CHECKED_COOKIE_CONSENT_FAIL,
  payload: undefined,
});

type isCheckedByKey = { [key: string]: boolean };

export function createSubmitCookieConsentsAction(): CookieConsentAction {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(setCheckedCookieConsentStart());

    try {
      const { selectedConsentIds, consentPageData } = getState().checkedCookieConsent;

      const defaultConsentId = consentPageData ? consentPageData.consentGroup : null;

      // Iterate through consents in reverse order to inherit consent acceptance from the strongest consent (last one)
      const consentsInReverseOrder = [...(consentPageData ? consentPageData.consents : [])]
        .reverse()
        // Exclude default selection from the request
        .filter((consent: ConsentItem) => consent.consentTextId !== defaultConsentId);

      const consentMap = consentsInReverseOrder.reduce((acc: isCheckedByKey, consent: ConsentItem) => {
        acc[consent.consentTextId] = selectedConsentIds?.includes(consent.consentTextId) ?? false;

        return acc;
      }, {});

      signalUpdatedConsents(consentMap);

      window.finnairCookieSnippet.setConsents(consentMap);

      dispatch(setCheckedCookieConsentSuccess());
    } catch (e) {
      dispatch(setCheckedCookieConsentFail());
    }
  };
}

export type ToggleCookieConsentAction = Action<typeof TOGGLE_COOKIE_CONSENTS, { [key: string]: string }>;
export type handleToggleAllCookieConsentsAction = Action<typeof TOGGLE_ALL_COOKIE_CONSENTS>;
export type FetchConsentInitDataStartAction = Action<typeof FETCH_COOKIE_CONSENT_INIT_DATA_START, void>;
export type FetchConsentInitDataSuccessAction = Action<typeof FETCH_COOKIE_CONSENT_INIT_DATA_SUCCESS, ReturnedConsents>;
export type SetCheckedCookieConsentStartAction = Action<typeof SET_CHECKED_COOKIE_CONSENT_START, void>;
export type SetCheckedCookieConsentSuccessAction = Action<typeof SET_CHECKED_COOKIE_CONSENT_SUCCESS, void>;
export type SetCheckedCookieConsentFailAction = Action<typeof SET_CHECKED_COOKIE_CONSENT_FAIL, void>;
