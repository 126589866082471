import * as React from 'react';
import styled from 'styled-components';
import { PureComponent } from 'react';
import { WhiteBgBox } from '../../common/Box';
import { PrimaryButton } from '../../common/buttons';
import { Icon } from '../../common/Icon';
import { MediaItem, MediaTitle } from '../../common/MediaItem';
import LoginButton from './LoginButton';
import { LogoutButton } from './LogoutButton';
import { breakPoint, color, font, spacing, value } from '../../assets/styles';

export type LoginPromptProps = {
  loggedIn: boolean;
  title: string;
  ingress: string;
  buttonText: string;
  navigateTo: () => void;
  redirUri: string;
};

const ButtonWrapper = styled.span`
  display: inline-block;
  padding-top: ${spacing.small};

  @media (min-width: ${breakPoint.desktopMin}) {
    padding-right: ${spacing.medium};
    padding-top: ${spacing.medium};
  }
`;

const LoginBox = styled(WhiteBgBox)`
  max-width: ${value.pageContentWidth};
  margin: 0 auto;
`;

export const PromptText = styled.div`
  color: ${color.charcoal};
  font-size: ${font.smallPlus};
  margin: 0;
  padding: 0;
  padding-top: ${spacing.small};

  @media (max-width: ${breakPoint.mobileMax}) {
    margin-bottom: ${spacing.small};
  }
`;

export class LoginPrompt extends PureComponent<LoginPromptProps> {
  render() {
    const { loggedIn, navigateTo, title, ingress, buttonText, redirUri } = this.props;
    return (
      <LoginBox>
        <MediaItem media={<Icon name={'plusSymbol'} />}>
          <MediaTitle>{title}</MediaTitle>
          {!loggedIn ? <PromptText>{ingress}</PromptText> : ''}
          <ButtonWrapper>{loggedIn ? <LogoutButton /> : <LoginButton redirUri={redirUri} />}</ButtonWrapper>
          <ButtonWrapper>
            <PrimaryButton onClick={navigateTo} disabled={!loggedIn}>
              {buttonText}
            </PrimaryButton>
          </ButtonWrapper>
        </MediaItem>
      </LoginBox>
    );
  }
}
