import { LanguageState } from './language.interface';
import { createReducer } from '../../common/reducer-utils';
import { LanguageActionTypes, TOGGLE_LANGUAGE_SWITCHER } from './LanguageSwitcherActions';

export default function languageReducer(language: string) {
  const initialState: LanguageState = {
    currentLocale: language,
    showLanguageSwitcher: false,
  };
  return createReducer<LanguageState, LanguageActionTypes>(initialState, {
    [TOGGLE_LANGUAGE_SWITCHER]: (state: LanguageState) => ({
      ...state,
      showLanguageSwitcher: !state.showLanguageSwitcher,
    }),
  });
}
