import * as React from 'react';
import { StatelessComponent } from 'react';
import { Icon } from '../../common/Icon';
import styled from 'styled-components';
import { i18n } from '../../app/i18n';
import { color } from '../../assets/styles';

const TitleContainer = styled.div`
  width: 160px;
  font-family: FinnairSans;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${color.black};
`;

const IconBorder = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${color.white};
  border: solid 2px ${color.charcoal};
  border-radius: 24px;
`;

const IconBorderFlex = styled(IconBorder)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StatusNumber = styled.div`
  font-family: FinnairSans;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${color.blue};
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const IconRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const LabelRowContainer = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const IconDivider = styled.div`
  width: 130px;
  height: 2px;
  background-color: ${color.charcoal};
`;

const iconDiviver = (index: number) => <IconDivider key={`iconDiviver${index}`} />;

const numberStatus = (index: number) => (
  <IconBorderFlex key={`IconBorderFlex_${index}`}>
    <StatusNumber>{index}</StatusNumber>
  </IconBorderFlex>
);

const checkedStatus = (index: number) => (
  <IconBorder key={`iconBorder_${index}`}>
    <Icon name={'checkMarkBlock'} size="medium" />
  </IconBorder>
);

type Props = {
  status: string;
};

const getStatus = (index: number) => {
  const statusBarElements = [];
  for (let i = 1; i <= index; i++) {
    statusBarElements.push(checkedStatus(i));
    statusBarElements.push(iconDiviver(i));
  }
  for (let i = index + 1; i <= 4; i++) {
    statusBarElements.push(numberStatus(i));
    statusBarElements.push(iconDiviver(i));
  }
  statusBarElements.pop();
  return statusBarElements;
};

const getStatusIndex = (status: string) => {
  if (status === 'CREATED' || status === 'WAITING_PASSPORT_CHECK') {
    return 1;
  } else if (status === 'COMPLETED') {
    return 3;
  } else if (status === 'DATA_REMOVED') {
    return 4;
  } else {
    return 2;
  }
};

export const Progress: StatelessComponent<Props> = ({ status }) => (
  <MainContainer>
    <IconRowContainer>{getStatus(getStatusIndex(status))}</IconRowContainer>
    <LabelRowContainer>
      <TitleContainer key="TitleContainer_1">{i18n('downloadReport.requestSubmitted')}</TitleContainer>
      <TitleContainer key="TitleContainer_2">{i18n('downloadReport.requestInProgress')}</TitleContainer>
      <TitleContainer key="TitleContainer_3">{i18n('downloadReport.requestReady')}</TitleContainer>
      <TitleContainer key="TitleContainer_4">{i18n('downloadReport.requestInactive')}</TitleContainer>
    </LabelRowContainer>
  </MainContainer>
);
