import * as React from 'react';
import { StatelessComponent } from 'react';
import styled from 'styled-components';
import { color } from '../../assets/styles';

const NoteContainer = styled.div`
  padding: 10px;
  color: ${color.darkGray}
  background-color: ${color.lighterBlue}
  border-radius: 8px;
`;

const NoteLabel = styled.span`
  font-family: FinnairSans;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: ${color.charcoal};
  font-weight: 500;
`;

const NoteText = styled.span`
  font-family: FinnairSans;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: ${color.charcoal};
`;

type NoteProps = {
  title: string;
  text: string;
};

export const Note: StatelessComponent<NoteProps> = ({ title, text }) => (
  <NoteContainer>
    <NoteLabel>{title}: </NoteLabel>
    <NoteText>{text}</NoteText>
  </NoteContainer>
);
