import { MouseEvent } from 'react';
import * as React from 'react';
import { PureComponent } from 'react';
import { i18n } from '../../../app/i18n';
import { PrimaryButton } from '../../../common/buttons';
import { Centered } from '../../../common/layout';
import { PaddedH2, PaddedIngress } from '../_view';

export type FailureScreenProps = {
  resetState: Function;
};

export class LightProcessFailureScreen extends PureComponent<FailureScreenProps> {
  render() {
    return (
      <Centered>
        <PaddedH2>{i18n('exportDataFailure.title')}</PaddedH2>
        <PaddedIngress>{i18n('exportDataFailure.ingress')}</PaddedIngress>
        <PrimaryButton onClick={this._handleClick}>{i18n('exportDataFailure.callToAction')}</PrimaryButton>
      </Centered>
    );
  }

  _handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.resetState();
  };
}
