import * as React from 'react';
import { Component, FocusEvent, LabelHTMLAttributes } from 'react';
import styled from 'styled-components';
import { breakPoint, color, font, spacing } from '../assets/styles';
import { Centered } from './layout';
import { WrappedFieldProps } from 'redux-form';
//import { Icon } from './Icon';

type LabelProps = { moveLabelOnTopOfField: boolean } & LabelHTMLAttributes<HTMLLabelElement>;

export const INPUT_HEIGHT = '1.25rem';

export const FieldWrapper = styled.div``;

export const FieldContainer = styled.fieldset`
  position: relative;
  display: block;
  border: 0;
  padding: ${spacing.smallPlus} 0;
  text-align: left;
  max-width: 100%;
`;

export const LabelSpan = styled.span<LabelProps>`
  position: absolute;
  top: ${spacing.smallPlus};
  font-size: ${(props) => (props.moveLabelOnTopOfField ? font.regular : font.smallPlus)};
  transform: translateY(${(props) => (props.moveLabelOnTopOfField ? '0' : '-1rem')});
  transition: 0.3s all;
`;

export const HintDiv = styled.div`
  font-size: ${font.smallPlus};
  color: ${color.darkGray};
  padding-bottom: 20px;
  text-align: right;
`;

export const HintText = styled.div`
  padding-right: 20px;
`;

export const HintIconContainer = styled.div`
  float: right;
  top: 2px;
  position: relative;
`;

export const ErrorSpan = styled.div`
  font-size: ${font.smallPlus};
  font-weight: 600;
  color: ${color.errorRed};
  padding-bottom: 20px;
  text-align: left;
`;

export const CustomErrorSpan = styled.div`
  margin-top: 1.25rem;
  font-size: ${font.smallPlus};
  font-weight: 600;
  color: ${color.errorRed};
  padding-bottom: 20px;
  text-align: left;
`;

const labelZIndex = 1;
export const Label = styled.label`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: ${labelZIndex};

  display: block;
  color: ${color.darkGray};

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 1rem;
    pointer-events: none;
    transition:
      0.3s width ease,
      0.3s background-color ease;
  }
  &:before {
    width: 100%;
    height: 1px;
    background-color: ${color.lightGray};
  }
  &:after {
    height: 3px;
    width: 0;
    background-color: ${color.blue};
    input.visited + & {
      width: 100%;
    }
    input:focus + & {
      background-color: ${color.blue};
    }
    input.visited:invalid:not(:focus) + & {
      background-color: ${color.heather};
    }
    input.error:not(:focus) + & {
      background-color: ${color.errorRed};
    }
  }
`;

export const InputField = styled.input`
  /* Stay above label for mouse accessibility */
  position: relative;
  z-index: ${labelZIndex + 1};
  /* /Stay above label for mouse accessibility */
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  font-size: 1rem;
  line-height: 0.8125;
  height: ${INPUT_HEIGHT};
  color: ${color.charcoal};
  background-color: transparent;
  transition: 0.3s all;
`;

export type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  fieldId: string;
  fieldValue: string;
  label: string;
  name?: string;
  hint?: string;
  touched: boolean;
  error: boolean;
};

export type InputGroupState = {
  fieldId: string;
  hasFocus: boolean;
};

let inputCounter: number = 0;
export class ReduxInputGroup extends Component<Props, InputGroupState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasFocus: false,
      fieldId: `input-${inputCounter++}`,
    };
  }

  _handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    this.setState({ hasFocus: true });
    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  };

  _handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    this.setState({ hasFocus: false });
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  };

  render() {
    const { onFocus, onBlur, fieldValue, label, touched, error, hint, ...rest } = this.props;
    const { fieldId, hasFocus } = this.state;

    const classNames = [];
    if (touched) {
      classNames.push('visited');
    }
    if (error) {
      classNames.push('error');
    }
    const classNamesAsString = classNames.join(' ');

    return (
      <FieldWrapper>
        <FieldContainer className={classNamesAsString}>
          <InputField
            id={this.state.fieldId}
            value={this.props.fieldValue}
            className={classNamesAsString}
            //onFocus={this._handleFocus}
            onBlur={this._handleBlur}
            {...rest}
          />
          <Label htmlFor={fieldId}>
            <LabelSpan moveLabelOnTopOfField={!hasFocus && !fieldValue}>{label}</LabelSpan>
          </Label>
        </FieldContainer>
        {hint && (
          <HintDiv>
            <HintIconContainer>{/*<Icon name={'help'} size={'smallMinus'} />*/}</HintIconContainer>
            <HintText>{hint}</HintText>
          </HintDiv>
        )}
        {error && <ErrorSpan>{error}</ErrorSpan>}
      </FieldWrapper>
    );
  }
}

type TextInputProps = {
  label: string;
  name: string;
  hint?: string;
} & WrappedFieldProps;

export const DisabledTextInput: React.StatelessComponent<TextInputProps> = ({
  input,
  label,
  hint,
  meta: { touched, error, warning },
  ...otherProps
}) => {
  return (
    <ReduxInputGroup
      disabled={true}
      fieldId={input.name}
      fieldValue={input.value}
      label={label}
      hint={hint}
      touched={touched}
      error={touched && error}
      {...input}
      {...otherProps}
    />
  );
};

export const TextInput: React.StatelessComponent<TextInputProps> = ({
  input,
  label,
  hint,
  meta: { touched, error, warning },
  ...otherProps
}) => {
  return (
    <ReduxInputGroup
      fieldId={input.name}
      fieldValue={input.value}
      label={label}
      hint={hint}
      touched={touched}
      error={touched && error}
      {...input}
      {...otherProps}
    />
  );
};

/*
 * Ugly hack for getting the form to retain the correct properties
 * for the `form` tag when extending the component styles using `withComponent`.
 *
 * See https://github.com/styled-components/styled-components/issues/1315
 */
const form = styled.form``;
export const CenteredForm = styled(Centered.withComponent('form'))`
  flex-flow: column wrap;
  align-items: center;
  max-width: 30rem;
  margin: ${spacing.xLarge} auto;
  @media (max-width: ${breakPoint.mobileMax}) {
    margin: ${spacing.small};
  }
` as typeof form;
