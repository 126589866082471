import styled from 'styled-components';
import { color, font } from '../../../assets/styles';

export const Content = styled.div`
  margin: 1.2rem;

  .consent-texts {
    user-select: none;
  }
`;

export const Paragraph = styled.p`
  width: 100%;
  font-size: ${font.smallPlus};
  color: ${color.black};
  font-weight: normal;
`;
