import * as React from 'react';
import { StatelessComponent } from 'react';
import { BaseButton } from '../../../common/buttons';
import { Icon } from '../../../common/Icon';
import styled from 'styled-components';

const TitleContainer = styled.div`
  padding-left: 10px;
`;

type Props = {
  title: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const AddItemButton: StatelessComponent<Props> = ({ title, onClick }) => (
  <BaseButton onClick={onClick} type="button">
    <Icon name={'addSymbol'} size="small" />
    <TitleContainer>{title}</TitleContainer>
  </BaseButton>
);
