import { CookieConsentState } from './cookieConsent.interface';
import {
  CookieConsentsActionTypes,
  SET_CHECKED_COOKIE_CONSENT_FAIL,
  SET_CHECKED_COOKIE_CONSENT_START,
  SET_CHECKED_COOKIE_CONSENT_SUCCESS,
  SetCheckedCookieConsentFailAction,
  FETCH_COOKIE_CONSENT_INIT_DATA_START,
  FETCH_COOKIE_CONSENT_INIT_DATA_SUCCESS,
  FetchConsentInitDataSuccessAction,
  TOGGLE_COOKIE_CONSENTS,
  TOGGLE_ALL_COOKIE_CONSENTS,
  ToggleCookieConsentAction,
} from './CookieConsentActions';
import { createReducer } from '../../common/reducer-utils';
import { CONSENT_STATUS, ConsentItemWithText, ReturnedConsents } from '../../snippet/cookie-snippet.interface';

const initialState: CookieConsentState = {
  consentPageData: undefined,
  loading: true,
  canProceedFurther: true,
  selectedConsentIds: [],
  changesSaved: false,
};

export const addDefaultConsent = (consentPageData: ReturnedConsents): ReturnedConsents => {
  return {
    ...consentPageData,
    consents: [
      { consentTextId: consentPageData.consentGroup, text: consentPageData.introductionText } as ConsentItemWithText,
    ].concat(consentPageData.consents),
  };
};

export const cookieConsentReducer = createReducer<CookieConsentState, CookieConsentsActionTypes>(initialState, {
  [TOGGLE_COOKIE_CONSENTS]: (state: CookieConsentState, action: ToggleCookieConsentAction) => {
    if (state.selectedConsentIds?.includes(action.payload.itemId)) {
      return {
        ...state,
        selectedConsentIds: state.selectedConsentIds.filter((id) => id !== action.payload.itemId),
      };
    }
    return {
      ...state,
      selectedConsentIds: state.selectedConsentIds?.concat(action.payload.itemId),
    };
  },
  [TOGGLE_ALL_COOKIE_CONSENTS]: (state) => ({
    ...state,
    // filter out first default consent
    selectedConsentIds: state.consentPageData?.consents.slice(1).map((consent) => consent.consentTextId),
  }),
  [FETCH_COOKIE_CONSENT_INIT_DATA_START]: (state) => ({ ...state, loading: true }),
  [FETCH_COOKIE_CONSENT_INIT_DATA_SUCCESS]: (state, action: FetchConsentInitDataSuccessAction) => {
    const consentPageData = addDefaultConsent(action.payload);
    return {
      ...state,
      consentPageData: consentPageData,
      loading: false,
      selectedConsentIds: consentPageData.consents
        .filter((consent) => consent.consentStatus === CONSENT_STATUS.accepted)
        .map((consents) => consents.consentTextId),
    };
  },
  [SET_CHECKED_COOKIE_CONSENT_START]: (state) => ({
    ...state,
    canProceedFurther: false,
    changesSaved: false,
  }),
  [SET_CHECKED_COOKIE_CONSENT_SUCCESS]: (state) => ({
    ...state,
    canProceedFurther: true,
    changesSaved: true,
  }),
  [SET_CHECKED_COOKIE_CONSENT_FAIL]: (state, action: SetCheckedCookieConsentFailAction) => ({
    ...state,
    error: action.payload,
  }),
});
