import { combineReducers, Reducer } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { exportDataReducer } from '../modules/ExportData/exportDataReducer';
import { loginReducer } from '../modules/Login/loginReducer';
import { RootState } from './state.interface';
import languageReducer from '../modules/LanguageSwitcher/languageReducer';
import { cookieConsentReducer } from '../modules/CookieConsent/cookieConsentReducer';
import { marketingConsentReducer } from '../modules/MarketingConsent/marketingConsentReducer';
import { reportsReducer } from '../modules/DownloadReport/reportsReducer';

export const rootReducer = (language: string): Reducer<RootState> =>
  combineReducers<RootState>({
    checkedCookieConsent: cookieConsentReducer,
    checkedMarketingConsent: marketingConsentReducer,
    cookieConsent: cookieConsentReducer,
    exportData: exportDataReducer,
    language: languageReducer(language),
    login: loginReducer,
    form: formReducer,
    reports: reportsReducer,
  });
