import { applyMiddleware, compose, createStore, Middleware, Store, StoreEnhancer } from 'redux';
import thunk, { ThunkMiddleware, ThunkAction } from 'redux-thunk';
import { rootReducer } from './rootReducer';
import { RootState } from './state.interface';

export const middlewares: Middleware[] = [thunk as ThunkMiddleware<any, any>];
const storeEnhancers: StoreEnhancer<any>[] = [];

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: Function;
  }
}

declare module 'redux' {
  /*
   * https://github.com/reduxjs/redux-thunk/pull/259
   * Overload to add thunk support to Redux's dispatch() function.
   * Useful for react-redux or any other library which could use this type.
   */
  export interface Dispatch<A extends Action<any> = AnyAction> {
    <TReturnType, TState, TExtraThunkArg>(
      thunkAction: ThunkAction<TReturnType, TState, TExtraThunkArg, A>
    ): TReturnType;
  }
}

if (typeof window.__REDUX_DEVTOOLS_EXTENSION__ === 'function') {
  storeEnhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}

storeEnhancers.unshift(applyMiddleware(...middlewares));

export default (language: string): Store<RootState> => createStore(rootReducer(language), compose(...storeEnhancers));
