import styled from 'styled-components';
import { breakPoint, color, spacing, value } from '../../../assets/styles';
import { FullWidth } from '../../../common/layout';

const heroImage = require('./hero.jpg');

const imageMinHeight = '24rem';
const imageMinHeightMobile = '16rem';

export const HeroContainer = styled(FullWidth)`
  position: relative;

  @media (min-width: ${breakPoint.desktopMin}) {
    padding-bottom: ${spacing.xxxLarge};
  }
`;

export const HeroBgImage = styled.div`
  width: 100%;
  min-height: ${imageMinHeight};
  background-image: url('${heroImage}');
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;

  @media (max-width: ${breakPoint.mobileMax}) {
    min-height: ${imageMinHeightMobile};
  }
`;

export const HeroContentWrapper = styled.div`
  @media (max-width: ${breakPoint.mobileMax}) {
    max-width: 70%;
  }
  @media (min-width: ${breakPoint.largeScreenMin}) {
    width: ${value.appWrapperMaxWidth};
    margin: 0 auto;
  }
`;

export const ContentTitle = styled.div`
  box-sizing: border-box;
  background-color: ${color.white};
  word-wrap: break-word;

  @media (max-width: ${breakPoint.mobileMax}) {
    margin-left: ${spacing.xSmall};
    padding: ${spacing.medium};
  }
  @media (min-width: ${breakPoint.desktopMin}) {
    transform: translateY(${spacing.xLarge});
    margin-left: 10%;
    padding: ${spacing.small} ${spacing.xLarge};
    padding-top: ${spacing.xLarge};
    width: ${value.subContentWidth};
    min-width: ${320 - 2 * 32}px;
  }
  @media (min-width: ${breakPoint.largeScreenMin}) {
    margin-left: 0;
  }
`;
