import * as React from 'react';
import { FormEvent, PureComponent } from 'react';
import { i18n } from '../../../app/i18n';
import { PnrFormType } from '../exportData.interface';
import { RadioInput } from './_view/CompanyRadio';

import { FieldContainer, TabContainer, FlexItem, Legend, TabTextContainer } from './_view/Containers';
import { TabBox } from '../../../common/Box';
import { SimpleLoginPrompt } from '../../Login/SimpleLoginPrompt';
import { NonLoggedInActions } from '../../Login/NonLoggedInOptions';

export type CompanySelectionProps = {
  onChange: (type: PnrFormType) => void;
  source: string;
  navigateTo: () => void;
  loggedIn: boolean;
};
export type CompanySelectionState = {
  selected: PnrFormType;
};

export const ICON_ORDER: PnrFormType[] = ['finnairPnr', 'aurinkomatkatBooking'];

export class CompanySelection extends PureComponent<CompanySelectionProps, CompanySelectionState> {
  static DEFAULT_VALUE: PnrFormType = 'finnairPnr';
  state: CompanySelectionState = {
    selected: CompanySelection.DEFAULT_VALUE,
  };

  get value(): PnrFormType {
    return this.state.selected;
  }

  render() {
    const { selected } = this.state;
    const linkToForm = `/export-data/${this.state.selected}`;
    return (
      <FieldContainer role="radiogroup" aria-labelledby="companySelectionTitle">
        <Legend id="companySelectionTitle">{i18n('exportSingleBookingForm.tabs.title')}</Legend>
        <TabContainer>
          {ICON_ORDER.map((pnrFormType: PnrFormType) => (
            <FlexItem key={pnrFormType}>
              <RadioInput
                value={pnrFormType}
                selected={pnrFormType === selected}
                onChange={this._handleChange}
                source={this.props.source}
              />
            </FlexItem>
          ))}
        </TabContainer>
        <TabBox>
          <TabTextContainer>
            <span>{i18n('extendedDataRequestForm.prefillInfo')}</span>
          </TabTextContainer>

          <TabTextContainer>
            <SimpleLoginPrompt
              requestType={selected}
              tipText={i18n('extendedDataRequestForm.tipForMember')}
              loggedIn={this.props.loggedIn}
              buttonText={i18n('extendedDataRequestForm.loginButton')}
              loggedInBttonText={i18n('extendedDataRequestForm.accessForm')}
              navigateTo={this.props.navigateTo}
              redirUri={`${window.location.href}/${this.state.selected}`}
            />
          </TabTextContainer>

          <TabTextContainer>
            <NonLoggedInActions
              loggedIn={this.props.loggedIn}
              descriptionLabel={i18n('extendedDataRequestForm.tipForNonMember')}
              buttonText={i18n('extendedDataRequestForm.acceessWithout')}
              targetUri={linkToForm}
            />
          </TabTextContainer>
        </TabBox>
      </FieldContainer>
    );
  }

  private _handleChange = (event: FormEvent<HTMLInputElement>) => {
    const selected = event.currentTarget.value as PnrFormType;
    this.setState({ selected });
    this.props.onChange(selected);
  };
}
