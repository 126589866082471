import * as React from 'react';

import styled from 'styled-components';
import { color, spacing, font } from '../../assets/styles';

const Label = styled.label`
  display: flex;
  margin: ${spacing.xxSmall} 0 ${spacing.xSmall} 0;
`;

const Paragraph = styled.p`
  display: inline;
  font-family: FinnairSans;
  font-size: ${font.smallPlus};
  line-height: ${font.regular};
  color: ${color.black};
`;

const Input = styled.input`
  margin: ${spacing.xxSmall} ${spacing.small} ${spacing.xSmall} 0;
  display: inline;
  min-width: 1rem;
`;

const InlineWithInlineChildren = styled.div`
  display: inline;
  font-size: ${font.smallPlus};
  line-height: ${font.regular};
  margin: ${spacing.xxSmall} 0 ${spacing.xSmall} 0;
  width: 90%;

  > * {
    display: inline;
  }
`;

interface Props {
  id: string;
  label: string;
  isChecked: () => boolean;
  handleCheckboxChange: (id: string) => void;
  htmlContent?: string;
}

class Checkbox extends React.Component<Props> {
  toggleCheckboxChange = () => {
    const { handleCheckboxChange, id } = this.props;
    handleCheckboxChange(id);
  };

  render() {
    const { label, isChecked } = this.props;
    return (
      <Label className={isChecked() ? 'selected' : ''}>
        <Input type="checkbox" onChange={this.toggleCheckboxChange} checked={isChecked() || false} />
        {this.props.htmlContent ? (
          <InlineWithInlineChildren dangerouslySetInnerHTML={{ __html: this.props.htmlContent }} />
        ) : (
          <Paragraph>{label}</Paragraph>
        )}
      </Label>
    );
  }
}

export default Checkbox;
