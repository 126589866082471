import ConsentItem = Components.Schemas.ConsentItem;
import GetConsents = Components.Schemas.GetConsents;
import ConsigneeConsents = Components.Schemas.ConsigneeConsents;

/**
 * The keys of the values that are stored in the cookie.
 * We omit textHash since it serves no obvious purpose here, only
 * relevant at API side.
 */
export type StoredKeys = Exclude<keyof ConsentItem, 'textHash'>;

/**
 * Type that is used for storing the states of the consents without
 * text hashes to cookie. Can be used to reconstruct the state
 */
export type StoredConsentItem = Pick<Required<ConsentItem>, StoredKeys>;

export type ConsentItemWithText = Required<ConsentItem> & { text: string };
/**
 * The type returned from the cookie snippet
 */
export type ReturnedConsents = Pick<Required<GetConsents>, Exclude<keyof GetConsents, 'consents'>> & {
  consents: ConsentItemWithText[];
};

export type ConsentStatus = ConsentItem['consentStatus'];

export type CookieIdResponse = { cookieId: string };
export type ConsentIdToBooleanMap = { [consentTextId: string]: boolean };

export const CONSENT_STATUS: { [k in ConsentStatus]: k } = {
  accepted: 'accepted',
  declined: 'declined',
  withdrawn: 'withdrawn',
};

export type CookieSnippet = {
  getConsents: (onSuccess: (consents: ReturnedConsents) => void) => void;
  setConsents: (consentMap: ConsentIdToBooleanMap) => ConsigneeConsents;
};

/* Add the global cookie snippet definition to window.finnairCookieSnippet */
declare global {
  interface Window {
    finnairCookieSnippet: CookieSnippet;
  }
}
