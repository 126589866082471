import styled from 'styled-components';
//import { color } from '../../../assets/styles';

export const DownloadContainer = styled.div`
  padding-top: 17px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DownloadButtonWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
`;

export const WithPadding = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;
