import styled from 'styled-components';
import { color } from '../../assets/styles';

export const Title = styled.div`
  display: block;
  padding: 0;
  margin-top: 35px;
  text-align: left;
  color: ${color.blue};
  font-family: FinnairSans;
  font-size: 28px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
`;

export const BoldText = styled.div`
  display: block;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 30px;
  font-family: FinnairSans;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${color.black};
`;

export const NormalText = styled.div`
  display: block;
  padding: 0;
  margin-top: 30px;
  font-family: FinnairSans;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: ${color.charcoal};
`;
