import { StatelessComponent } from 'react';
import * as React from 'react';
import styled from 'styled-components';
import { color, spacing, value, font } from '../../assets/styles';
import { Centered } from '../layout';

const finnairEmblem = require('../../assets/images/finnair-emblem-white.svg');
const emblemWidth = '3rem';

const Box = styled.div`
  margin: 0 auto;
  max-width: ${value.pageContentWidth};
  overflow: hidden;
`;

const Title = styled.h2`
  border-top-left-radius: ${value.rounding};
  border-top-right-radius: ${value.rounding};
  color: ${color.blue};
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-size: 28px;
  font-weight: 900;
  text-transform: none;
  text-align: center;
`;
const Content = styled.div`
  border: none;
  padding: ${spacing.small};
  position: relative;
`;

export type Props = {
  title: string;
  children: any;
};

export const Heading: StatelessComponent<Props> = ({ title, children }: Props) => (
  <Box>
    <Title>{title}</Title>
    <Content>{children}</Content>
  </Box>
);

export const WhiteBgBox = styled(Centered)`
  background-color: ${color.white};
  border: 1px solid ${color.lightGray};
  overflow: hidden;
  max-width: ${value.pageContentWidth};
  margin: 0 auto ${spacing.medium} auto;
`;

export const TabBox = styled(Centered)`
  background-color: ${color.white};
  border-bottom-left-radius: ${value.rounding};
  border-bottom-right-radius: ${value.rounding};
  border: 1px solid ${color.lightGray};
  border-top: none;
  overflow: hidden;
  max-width: ${value.pageContentWidth};
  margin: 0 auto ${spacing.medium} auto;
`;
