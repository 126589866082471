import styled from 'styled-components';
import { breakPoint, color, font, spacing, weight } from '../../../assets/styles';

const finnairFull = require('../../../assets/images/finnair-logo-rgb.svg');
const finnairLogo = require('../../../assets/images/finnair-emblem.svg');
const login = require('../../../assets/images/login-loggedIn.svg');
const logout = require('../../../assets/images/login-loggedOut.svg');
const home = require('../../../assets/images/home.svg');
const backArrow = require('../../../assets/images/navi-arrow-back.svg');

export const HeaderDiv = styled.div`
  padding: 0;
  margin: 0;
  width: 100%;
  align-items: center;
  background-color: ${color.white};
  height: ${spacing.xxLarge};

  @media (max-width: ${breakPoint.mobileMax}) {
    height: ${spacing.xLarge};
  }

  .finnair-emblem {
    height: 0.875rem;
    @media (min-width: ${breakPoint.desktopMin}) {
      background: url('${finnairFull}') no-repeat center;
      min-width: 200px;
    }
    @media (max-width: ${breakPoint.mobileMax}) {
      background: url('${finnairLogo}') no-repeat center;
    }
  }
  .back-arrow {
    background: url('${backArrow}') no-repeat center;
    // TODO: Change this to use flex
    width: 10%;
  }
  .loginStyle,
  .home {
    text-align: center;
    box-sizing: border-box;
  }
  .loginStatusText,
  .homeText,
  .headerTextItem {
    font-size: ${font.smallPlus};
    letter-spacing: normal;
    color: ${color.blue};
    vertical-align: middle;
    font-weight: ${weight.regular};
    line-height: 4rem;

    @media (max-width: ${breakPoint.mobileMax}) {
      display: none;
    }
  }
  .loggedIn,
  .loggedOut,
  .homeIcon {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: middle;
    padding-right: ${spacing.small};
  }
  .loggedOut {
    background: url('${logout}') no-repeat center;
  }
  .loggedIn {
    background: url('${login}') no-repeat center;
  }
  .homeIcon {
    background: url('${home}') no-repeat center;
  }
  .flex-1 {
    flex: 1 0 0;
  }
  .flex-auto {
    flex: 0 0 auto;
  }
`;

export const Menu = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
  height: ${spacing.xxLarge};

  @media (max-width: ${breakPoint.mobileMax}) {
    height: ${spacing.xLarge};
  }
`;

export const Item = styled.li`
  height: 100%;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 ${spacing.xSmall};
`;

export const Separator = styled.div`
  width: 100%;
  height: 0.25rem;
  background: ${color.blue};
`;
