import styled from 'styled-components';
import { breakPoint, color, spacing, value, weight } from '../../../assets/styles';

export const BreadCrumbBar = styled.div`
  left: 0;
  right: 0;
  position: absolute;
  z-index: 2;

  background-color: rgba(255, 255, 255, 0.9);

  height: ${value.breadCrumbHeight};
  line-height: ${value.breadCrumbHeight};
  font-size: 13px; // Custom size
  text-decoration: none;
  padding: 0 ${spacing.small};

  @media (max-width: ${breakPoint.mobileMax}) {
    font-size: 12px; // Custom size
  }

  .bold {
    font-weight: ${weight.medium};
  }
  a {
    text-decoration: none;
    color: ${color.charcoal};
  }
`;
