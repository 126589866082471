import styled from 'styled-components';
import { color } from '../../../assets/styles';

export const WithPadding = styled.div`
  padding-left: 120px;
  padding-right: 120px;
  display: flex;
  flex-direction: column;
  align-content: center;
`;

export const TextContainer = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: FinnairSans;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
`;

export const AddressTextContainer = styled.div`
  font-family: FinnairSans;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
`;

export const BoldTextContainer = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 500;
`;

const borderWidth = '1px';
export const Section = styled.div`
  border-bottom: ${borderWidth} solid ${color.lightGray};
  background-color: ${color.white};
  padding-top: 0px;
  padding-bottom: 10px;
`;

export const SectionWithoutBorder = styled.div`
  background-color: ${color.white};
  padding-top: 20px;
  padding-bottom: 0px;
`;
export const FormSection = styled.div`
  border-bottom: ${borderWidth} solid ${color.lightGray};
  background-color: ${color.white};
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const FormInputSection = styled.div`
  background-color: ${color.white};
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
  border-top: none;
`;

export const SubmitContainer = styled.div`
  padding-top: 0;
  padding-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SubmitButtonWrapper = styled.div`
  margin-top: 17px;
  margin-bottom: 17px;
`;
