import styled from 'styled-components';

type RowProps = {
  wrap?: 'wrap' | 'nowrap';
  alignItems?: 'stretch' | 'center' | 'start' | 'end';
};

export const Row = styled.div<RowProps>`
  display: flex;
  flex-flow: row ${(props) => props.wrap || 'nowrap'}
  align-items: ${(props) => props.alignItems || 'stretch'};
`;

export const Col = styled.div`
  flex: 1 0 0;
`;

export const AutoCol = styled.div`
  flex: 0 0 auto;
`;
