import styled from 'styled-components';
import { color, font, spacing, value } from '../assets/styles';

const boxShadowBorder = (borderColor: string) => `
  box-shadow: inset 0 0 0 1px ${borderColor};
`;
export const BaseButton = styled.button`
  // TODO: Antti will find a way to fix the world
  padding: 0.75rem 1rem 0.875rem;
  text-decoration: none;
  font-size: ${font.smallPlus};
  font-weight: bold;
  line-height: 1;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  margin: 0 auto;
  border: none;
  border-radius: ${value.roundingSmall};
  box-shadow: none;
  min-width: 14.5rem;
  cursor: pointer;
  background: none;

  &:hover {
    text-decoration: none;
  }

  &:disabled {
    cursor: default;
  }
`;

export const BaseLink = styled.a`
  padding: 0.75rem 1rem 0.875rem;
  text-decoration: none;
  font-size: ${font.smallPlus};
  font-weight: bold;
  line-height: 1;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  margin: 0 auto;
  border: none;
  border-radius: ${value.roundingSmall};
  box-shadow: none;
  min-width: 14.5rem;
  cursor: pointer;
  background: none;

  &:hover {
    text-decoration: none;
  }

  &:disabled {
    cursor: default;
  }
`;

export const PrimaryButton = styled(BaseButton)`
  background-color: ${color.blue};
  color: ${color.white};

  &:hover {
    background-color: ${color.lightBlue};
  }

  &:disabled {
    background-color: ${color.darkGray};
  }
`;

export const PrimaryLink = styled(BaseLink)`
  background-color: ${color.blue};
  color: ${color.white};

  &:hover {
    background-color: ${color.lightBlue};
  }

  &:disabled {
    background-color: ${color.darkGray};
  }
`;

export const AurinkomatkatPrimaryButton = styled(BaseButton)`
  background-color: ${color.orange};
  color: ${color.white};

  &:hover {
    background-color: ${color.lightOrange};
  }

  &:disabled {
    background-color: ${color.darkGray};
  }
`;

export const ButtonWithBottomMargin = styled(PrimaryButton)`
  margin-bottom: ${spacing.large};
`;

export const RoundedButton = styled(ButtonWithBottomMargin)`
  height: 40px;
  background: ${color.nordicBlue};
  border-radius: 24px;
  margin: 0.5rem 0;
  width: 100%;
  text-transform: none;
`;

export const RoundedButtonWhite = styled(RoundedButton)`
  border: 1px solid ${color.nordicBlue};
  background: ${color.white};
  color: ${color.nordicBlue};
  &:hover {
    background-color: ${color.white};
  }
`;

export const PrimaryLinkButton = styled(PrimaryButton)<{ href: string; as?: 'a' | undefined }>``;

export const PrimaryLinkLink = styled(PrimaryLink)<{ href: string; as?: 'a' | undefined }>``;

export const SecondaryButton = styled(BaseButton)`
  background-color: ${color.white};
  ${boxShadowBorder('currentColor')};
  color: ${color.blue};

  &:hover {
    border-color: ${color.blue}cc;
    color: ${color.blue}cc;
  }

  &:disabled {
    background-color: ${color.gray};
    box-shadow: none;
    color: ${color.white};
  }
`;

export const SecondaryLinkButton = styled(SecondaryButton)<{ href: string; as?: 'a' | undefined }>``;

export const SmallPrimaryButton = styled(PrimaryButton)`
  min-width: 160px;
`;

export const SmallSecondaryButton = styled(SecondaryButton)`
  min-width: 160px;
`;
