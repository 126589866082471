import { ReportsState } from './reports.interface';
import {
  ReportsActionTypes,
  FetchReportsInitDataSuccessAction,
  FETCH_REPORTS_INIT_DATA_START,
  FETCH_REPORTS_INIT_DATA_SUCCESS,
} from './ReportsActions';
import { createReducer } from '../../common/reducer-utils';

const initialState: ReportsState = {
  availableReports: undefined,
  loading: true,
};

export const reportsReducer = createReducer<ReportsState, ReportsActionTypes>(initialState, {
  [FETCH_REPORTS_INIT_DATA_START]: (state) => ({ ...state, loading: true }),
  [FETCH_REPORTS_INIT_DATA_SUCCESS]: (state, action: FetchReportsInitDataSuccessAction) => {
    const reports = action.payload;
    return {
      ...state,
      availableReports: reports,
      loading: false,
    };
  },
});
