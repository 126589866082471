import { CookieConsentPage } from './CookieConsentPage';
import { connect } from 'react-redux';
import { RootState } from '../../app/state.interface';
import {
  createSubmitCookieConsentsAction,
  handleToggleCookieConsents,
  handleToggleAllCookieConsents,
} from './CookieConsentActions';

export default connect(
  (state: RootState) => ({
    selectedConsentIds: state.checkedCookieConsent.selectedConsentIds,
    consentPageData: state.checkedCookieConsent.consentPageData,
    showSpinner: state.checkedCookieConsent.loading,
    buttonStatus: state.checkedCookieConsent.canProceedFurther,
    cookiesSavedSuccessfully: state.checkedCookieConsent.changesSaved,
  }),
  (dispatch: any) => ({
    handleToggleCookieConsents: (itemId: string) => {
      dispatch(handleToggleCookieConsents(itemId));
    },
    handleToggleAllCookieConsents: () => {
      dispatch(handleToggleAllCookieConsents());
    },
    handleSubmitCookieConsents: () => {
      dispatch(createSubmitCookieConsentsAction());
    },
  })
)(CookieConsentPage);
