import * as React from 'react';
import { StatelessComponent } from 'react';
import styled from 'styled-components';
import { color, spacing, value, font } from '../../../assets/styles';
const finnairEmblem = require('./aurinkomatkat.png');
const emblemWidth = '3rem';

const Box = styled.div`
  margin: 0 auto;
  max-width: ${value.pageContentWidth};
  overflow: hidden;
`;

const Title = styled.h2`
  border-top-left-radius: ${value.rounding};
  border-top-right-radius: ${value.rounding};
  background-color: ${color.white};
  color: ${color.orange};
  box-sizing: border-box;
  background-image: url('${finnairEmblem}');
  background-repeat: no-repeat;
  background-position: calc(100% - ${spacing.medium}) center;
  background-size: ${emblemWidth} auto;
  padding: ${spacing.small} calc(${emblemWidth} + 2 * ${spacing.medium}) ${spacing.small} ${spacing.medium};
  margin: 0;
  font-size: ${font.regular};
  border-style: solid;
  border-width: 1px 1px;
  border-color: rgb(215, 215, 215);
`;

const Content = styled.div`
  border-style: solid;
  border-width: 0 1px 1px;
  border-color: ${color.lightGray};
  box-sizing: border-box;
  padding: ${spacing.small};
  background-color: ${color.white};
  position: relative;
`;

export type AurinkomatkatBoxProps = {
  title: string;
};

export const AurinkomatkatBox: StatelessComponent<AurinkomatkatBoxProps> = ({ title, children }) => {
  return (
    <Box>
      <Title>{title}</Title>
      <Content>{children}</Content>
    </Box>
  );
};
