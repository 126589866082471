import * as React from 'react';
import { Field } from 'redux-form';
import { ReduxFormExpand } from '../../../../common/Expand/ReduxFormExpand';
import { titleArea, openIcon, closedIcon } from './expandParts';
import { FormInputSection } from '../Containers';
import { TextInput } from '../../../../common/reduxForms';
import * as validation from '../validations';
import { i18n } from '../../../../app/i18n';

export type FeedbackProps = {
  requestType: string;
};

const renderFeedbackFields = (requestType: string) => {
  let hintText = i18n('exportDataScreen.feedbackDetailsHintFinnair');
  if (requestType === 'aurinkomatkatBooking') {
    hintText = i18n('exportDataScreen.feedbackDetailsHintAurinkomatkat');
  }
  return (
    <FormInputSection>
      <Field
        name="questionFeedbackDetails"
        component={TextInput}
        label={i18n('exportDataScreen.feedbackDetails')}
        hint={hintText}
        validate={[validation.required]}
      />
    </FormInputSection>
  );
};

const FinnairPlusExpand: React.StatelessComponent<FeedbackProps> = (props: FeedbackProps) => {
  const { requestType } = props;
  return (
    <ReduxFormExpand
      openIcon={openIcon()}
      closedIcon={closedIcon()}
      title={titleArea(i18n('exportDataScreen.feedbackTitle'))}
      content={renderFeedbackFields(requestType)}
      {...props}
    />
  );
};

export const FeedbackFields = (props: FeedbackProps) => {
  const { requestType } = props;
  return (
    <Field
      name="QUESTION_FEEDBACK"
      component={FinnairPlusExpand}
      {...{
        requestType,
      }}
    />
  );
};
