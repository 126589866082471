import * as React from 'react';
import { Field } from 'redux-form';
import { ReduxFormExpand } from '../../../../common/Expand/ReduxFormExpand';
import { titleArea, openIcon, closedIcon } from './expandParts';
import { FormInputSection, BoldTextContainer, TextContainer } from '../Containers';
import { TextInput } from '../../../../common/reduxForms';
import * as validation from '../validations';
import { i18n } from '../../../../app/i18n';

export type AdditionaProps = {
  requestType: string;
};

const renderAdditionalFields = (requestType: string) => {
  let detailText = i18n('exportDataScreen.additionalParagraphFinnairOne');
  if (requestType === 'aurinkomatkatBooking') {
    detailText = i18n('exportDataScreen.additionalParagraphAurinkomatkat');
  }
  return (
    <FormInputSection>
      <TextContainer>{detailText}</TextContainer>
      {requestType === 'finnairPnr' ? (
        <TextContainer>{i18n('exportDataScreen.additionalParagraphFinnairTwo')}</TextContainer>
      ) : null}
    </FormInputSection>
  );
};

const AdditionalExpand: React.StatelessComponent<AdditionaProps> = (props: AdditionaProps) => {
  return (
    <ReduxFormExpand
      openIcon={openIcon()}
      closedIcon={closedIcon()}
      title={titleArea(i18n('exportDataScreen.additionalTitle'))}
      content={renderAdditionalFields(props.requestType)}
      {...props}
    />
  );
};

export const AdditionalFields = (props: AdditionaProps) => {
  const { requestType } = props;
  return (
    <Field
      name="QUESTION_ADDITIONAL"
      component={AdditionalExpand}
      {...{
        requestType,
      }}
    />
  );
};
