import { i18n } from '../../../app/i18n';

export const required = (value: any) =>
  value && ((typeof value === 'string' && value.trim().length > 0) || typeof value !== 'string')
    ? undefined
    : i18n('formValidationMessages.required');

export const maxLength = (max: number) => (value: string) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const minLength = (min: number) => (value: string) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const maxLength15 = maxLength(15);

export const minLength5 = minLength(15);

export const validateFinnairPlusNumber = (value: string) => {
  if (!value) {
    return undefined;
  }
  // 7-11 digits
  if (!RegExp('^\\d{9}$').test(value)) {
    return i18n('formValidationMessages.shouldBe9Numbers');
  }
  return undefined;
};

export const notNumbers = (value: string) => {
  if (!RegExp('^([^0-9]*)$').test(value)) {
    return i18n('formValidationMessages.shouldNotContainNumbers');
  }
  return undefined;
};

export const notCharacters = (value: string) => {
  if (!RegExp('^([^A-Za-z]*)$').test(value)) {
    return i18n('formValidationMessages.shouldNotContainChars');
  }
  return undefined;
};

export const commaSeparatedPNRs = (value: string) => {
  if (!value) {
    return undefined;
  }
  const pnrs = value
    .trim()
    .split(',')
    .map((a) => a.trim());
  const invalidPnr = pnrs.find((pnr) => !RegExp('^[a-zA-Z0-9]{6}$').test(pnr));
  if (invalidPnr) {
    return i18n('formValidationMessages.shouldBe6AlphaNum');
  }
  return undefined;
};

export const commaSeparatedAurinkomatkatBookingIds = (value: string) => {
  if (!value) {
    return undefined;
  }
  const bookingIds = value
    .trim()
    .split(',')
    .map((a) => a.trim());
  const invalidBookingId = bookingIds.find((bookingId) => !RegExp('^[56][0-9]{6}$').test(bookingId));
  if (invalidBookingId) {
    return i18n('formValidationMessages.shouldBe7Numbers');
  }
  return undefined;
};

export const validatePeriod = (value: string) => {
  if (!RegExp('^[21][09][0-9][0-9]/[01][0-9]/[0-3][0-9]-[21][09][0-9][0-9]/[01][0-9]/[0-3][0-9]$').test(value)) {
    return i18n('formValidationMessages.invalidPeriod');
  }
  return undefined;
};
