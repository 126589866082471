export const safeSetToStorage = (key: string, value: string, storage: Storage) => {
  try {
    storage.setItem(key, value);
  } catch (err) {
    // tslint:disable-next-line
    console.error('Session storage failed to set item', err);
  }
};

export const safeRemoveFromStorage = (key: string, storage: Storage) => {
  try {
    storage.removeItem(key);
  } catch (err) {
    // tslint:disable-next-line
    console.error('Session storage failed to remove item', err);
  }
};

export const safeGetFromStorage = (key: string, storage: Storage) => {
  try {
    return storage.getItem(key);
  } catch (err) {
    // tslint:disable-next-line
    console.error('Session storage failed to set item', err);
    return undefined;
  }
};
