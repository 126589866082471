import * as React from 'react';
import styled from 'styled-components';
import { spacing } from '../assets/styles';
import { AllowedFlag } from '../modules/LanguageSwitcher/language.interface';

const language = require('../assets/icons/flags-sprite.png');

const flagToSpritePosition: { [k in AllowedFlag]: string } = {
  AT: '-22px',
  AU: '0',
  BE: '-45px',
  CA: '-67px',
  CH: '-697px',
  CN: '-90px',
  CZ: '-135px',
  DE: '-247px',
  DK: '-157px',
  EE: '-180px',
  ES: '-652px',
  FI: '-202px',
  FR: '-225px',
  GB: '-742px',
  HK: '-292px',
  HR: '-112px',
  HU: '-315px',
  IE: '-832px',
  IL: '-360px',
  IN: '-337px',
  INT: '-270px',
  IS: '-900px',
  IT: '-382px',
  JP: '-405px',
  KR: '-630px',
  LT: '-450px',
  LV: '-427px',
  NL: '-472px',
  NO: '-495px',
  PL: '-517px',
  PT: '-540px',
  RU: '-562px',
  SE: '-675px',
  SG: '-585px',
  SI: '-607px',
  TH: '-720px',
  US: '-787px',
};

const FlagSpan = styled.span`
  display: inline-block;
  background: url('${language}') no-repeat center;
  background-size: 24px 1413px;
  background-position: 0 ${(props: FlagProps) => flagToSpritePosition[props.flag]};
  height: 1rem;
  width: 1.5rem;
  vertical-align: middle;
  padding-right: ${spacing.small};
`;
export interface FlagProps {
  flag: AllowedFlag;
}
export const Flag = (props: FlagProps) => <FlagSpan aria-hidden="true" {...props} />;
