import * as React from 'react';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { i18n } from '../../app/i18n';
import { RootState } from '../../app/state.interface';
import { MainContainer } from '../../common/layout';
import HeroImage from '../HeroImage';
import { UserProfile } from './exportData.interface';
import { clearBookingFormValues, setBookingFormValues } from './ExportDataActions';
import { ExtendedDataRequestForm } from './ExtendedDataRequestForm/ExtendedDataRequestForm';
import { Icon } from '../../common/Icon';
import { WhiteBgBox } from '../../common/Box';
import { LinkContent } from '../../common/LinkContent';
import styled from 'styled-components';

export const WithMargin = styled.div`
  margin-top: 20px;
`;

export type ExportDataScreenProps = RouteComponentProps<void> & {
  loggedIn: boolean;
  clearBookingFormValues: typeof clearBookingFormValues;
  setBookingFormValues: typeof setBookingFormValues;
  locale: string;
  profile: UserProfile;
};

export class ExportDataScreen extends PureComponent<ExportDataScreenProps> {
  // @TODO Warning: componentWillMount has been renamed, and is not recommended for use. See https://fb.me/react-async-component-lifecycle-hooks for details.
  // * Move code with side effects to componentDidMount, and set initial state in the constructor.
  UNSAFE_componentWillMount() {
    this.props.clearBookingFormValues();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <MainContainer>
        <HeroImage title={i18n('exportDataScreen.title')} ingress={i18n('exportDataScreen.ingress')} />

        <ExtendedDataRequestForm
          history={this.props.history}
          loggedIn={this.props.loggedIn}
          locale={this.props.locale}
          profile={this.props.profile}
        />
        <WithMargin>
          <WhiteBgBox>
            <LinkContent
              href={'/reports'}
              title={i18n('exportDataScreen.downloadTitle')}
              description={i18n('exportDataScreen.downloadIngress')}
              icon={<Icon name="exportDataIcon" />}
            />
          </WhiteBgBox>
        </WithMargin>
      </MainContainer>
    );
  }
}

export default connect(
  (state: RootState) => ({
    loggedIn: !!state.login.token,
    locale: state.language.currentLocale,
    profile: state.exportData.profile,
  }),
  { clearBookingFormValues, setBookingFormValues }
)(withRouter(ExportDataScreen));
