import styled from 'styled-components';
import { APP_WRAPPER_MAX_WIDTH, GRID_MARGIN } from '../modules/AppWrapper';

export const Centered = styled.div`
  text-align: center;
`;

export const MainContainer = styled.div`
  min-height: 100vh;
  position: relative;
  padding-bottom: 5rem;
`;

export const TextContainer = styled.div`
  margin-top: 20px;
`;

/**
 * Escapes the max-width limitation of the AppWrapper
 */
export const FullWidth = styled.div`
  margin-left: -${GRID_MARGIN}px;
  margin-right: -${GRID_MARGIN}px;
  @media (min-width: ${APP_WRAPPER_MAX_WIDTH}px) {
    margin-left: calc(-1 * ((100vw - ${APP_WRAPPER_MAX_WIDTH}px) / 2 + ${GRID_MARGIN}px));
    margin-right: calc(-1 * ((100vw - ${APP_WRAPPER_MAX_WIDTH}px) / 2 + ${GRID_MARGIN}px));
  }
`;
