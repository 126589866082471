import styled from 'styled-components';
import { breakPoint, font, color } from '../../assets/styles';

export const PrinciplesTitle = styled.div`
  margin-top: 60px;
  font-family: FinnairSans;
  font-size: ${font.large};
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: ${color.blue};

  @media (max-width: ${breakPoint.mobileMax}) {
    font-size: ${font.regularPlus};
  }
`;

export const Principles = styled.div`
  margin-top: 30px;
  font-family: FinnairSans;
  font-size: ${font.regular};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${color.black};

  @media (max-width: ${breakPoint.mobileMax}) {
    font-size: ${font.smallPlus};
  }
`;

export const PolicyText = styled.div`
  font-family: FinnairSans;
  font-size: ${font.regular};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${color.black};
  margin-bottom: 30px;

  @media (max-width: ${breakPoint.mobileMax}) {
    font-size: ${font.smallPlus};
  }
`;

export const PolicyLink = styled.a`
  font-family: FinnairSans;
  font-size: ${font.regular};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${color.blue};

  @media (max-width: ${breakPoint.mobileMax}) {
    font-size: ${font.smallPlus};
  }
`;
