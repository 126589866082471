import { Action } from '../../common/reducer-utils';
import { Dispatch } from 'redux';
import { RootState } from '../../app/state.interface';
import { ThunkAction } from 'redux-thunk';
import { CONFIG } from '../../app/config';
import { safeGet } from '../../utils/safe-get';
import { verifyTruthy } from '../../utils/verify';
import { PresignedUrlResponse, GdprRequestsReponse, GdprRequest } from './reports.interface';

export const FETCH_REPORTS_INIT_DATA_START = 'FETCH_REPORTS_INIT_DATA_START';
export const FETCH_REPORTS_INIT_DATA_SUCCESS = 'FETCH_REPORTS_INIT_DATA_SUCCESS';

export type ReportsActionTypes = typeof FETCH_REPORTS_INIT_DATA_START | typeof FETCH_REPORTS_INIT_DATA_SUCCESS;

export const fetchReportsInitDataStart = (): FetchReportsInitDataStartAction => ({
  type: FETCH_REPORTS_INIT_DATA_START,
  payload: undefined,
});

export const fetchReportsInitDataSuccess = (reports: Array<GdprRequest>): FetchReportsInitDataSuccessAction => ({
  type: FETCH_REPORTS_INIT_DATA_SUCCESS,
  payload: reports,
});

type CookieConsentAction = ThunkAction<Promise<void>, RootState, void, Action<any>>;

export const getPresignedUrlForPdf = async (
  accessToken: string,
  uuid: string,
  type: string,
  parameter: string
): Promise<string> => {
  const url = `${CONFIG.robotApiRoot}/requests/${uuid}/${type}/${parameter}/pdf-url`;
  const robotResponse = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    },
  });
  if (robotResponse.status !== 200) {
    throw new Error(`Fetching GdprReports failed: non-200 response from API: [${robotResponse.status}]`);
  }
  const responseJson: PresignedUrlResponse = await robotResponse.json();
  return responseJson.url;
};

export const getPresignedUrlForJson = async (
  accessToken: string,
  uuid: string,
  type: string,
  parameter: string
): Promise<string> => {
  const url = `${CONFIG.robotApiRoot}/requests/${uuid}/${type}/${parameter}/json-url`;
  const robotResponse = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    },
  });
  if (robotResponse.status !== 200) {
    throw new Error(`Fetching GdprReports failed: non-200 response from API: [${robotResponse.status}]`);
  }
  const responseJson: PresignedUrlResponse = await robotResponse.json();
  return responseJson.url;
};

const extractAccessToken = (state: RootState): string => {
  const accessToken = safeGet(state, 'login', 'token');
  return verifyTruthy(accessToken, 'Fetching reports failed: missing accessToken');
};

export function createFetchDataFromBackendAction(): CookieConsentAction {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(fetchReportsInitDataStart());
    const accessToken = extractAccessToken(getState());
    const url = `${CONFIG.robotApiRoot}/search/requests`;
    const robotResponse = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: accessToken,
      },
    });
    if (robotResponse.status !== 200) {
      throw new Error(`Fetching GdprReports failed: non-200 response from API: [${robotResponse.status}]`);
    }
    const responseJson: GdprRequestsReponse = await robotResponse.json();
    dispatch(fetchReportsInitDataSuccess(responseJson.gdprRequests));
  };
}

export type FetchReportsInitDataStartAction = Action<typeof FETCH_REPORTS_INIT_DATA_START, void>;
export type FetchReportsInitDataSuccessAction = Action<typeof FETCH_REPORTS_INIT_DATA_SUCCESS, Array<GdprRequest>>;
