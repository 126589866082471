import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { i18n } from '../../app/i18n';
import { MainContainer } from '../../common/layout';
import { Icon } from '../../common/Icon';
import HeroImage from '../HeroImage';
import { BoxWithHeading } from '../../common/Box';
import { Expand } from '../../common/Expand/Expand';
import { Progress } from './Progress';
import { DownloadContainer, DownloadButtonWrapper, WithPadding } from './Containers';
import { SmallPrimaryButton } from '../../common/buttons';
import { ReportTitle } from './Texts';
import { RootState } from '../../app/state.interface';
import { createFetchDataFromBackendAction, getPresignedUrlForPdf } from './ReportsActions';
import moment from 'moment';
import { SpinnerWhenLoading } from '../Spinner/index';
import { GdprRequest, DataRequest } from './reports.interface';

export const formatStringDate = (dateString: string) =>
  dateString ? moment(new Date(dateString)).format('DD.MM.YYYY HH:mm') : ' ';

const titleArea = (title: string) => <ReportTitle>{title}</ReportTitle>;

const expandIcon = () => <Icon name={'printBagTag'} size={'largePlus'} />;

export interface Props {
  reports: Array<GdprRequest>;
  token: string;
  fetchAvailableReports: () => void;
  showSpinner: boolean;
}

export class DownloadReport extends React.Component<Props> {
  // @TODO Warning: componentWillMount has been renamed, and is not recommended for use. See https://fb.me/react-async-component-lifecycle-hooks for details.
  // * Move code with side effects to componentDidMount, and set initial state in the constructor.
  UNSAFE_componentWillMount() {
    this.props.fetchAvailableReports();
  }

  _handleExportPdf = async (gdprRequest: GdprRequest, dataRequest: DataRequest) => {
    const { uuid } = gdprRequest;
    const { type, parameter } = dataRequest;
    const url = await getPresignedUrlForPdf(this.props.token, uuid, type, parameter);
    window.open(url, '_blank');
  };

  _renderReport = (gdprRequest: GdprRequest, dataRequest: any) => {
    const isDownloadDisabled = dataRequest.status !== 'COMPLETED';
    return (
      <DownloadContainer>
        <Progress status={dataRequest.status} />
        <DownloadButtonWrapper>
          <WithPadding>
            <SmallPrimaryButton
              disabled={isDownloadDisabled}
              onClick={() => this._handleExportPdf(gdprRequest, dataRequest)}
            >
              {i18n('downloadReport.downloadPdf')}
            </SmallPrimaryButton>
          </WithPadding>
        </DownloadButtonWrapper>
      </DownloadContainer>
    );
  };

  render() {
    return (
      <MainContainer>
        <HeroImage title={i18n('downloadReport.title')} ingress={i18n('downloadReport.ingress')} />
        <BoxWithHeading title={i18n('downloadReport.requests')}>
          <SpinnerWhenLoading loading={!this.props.reports}>
            {this.props.reports && this.props.reports.length === 0 && <span>{i18n('downloadReport.noData')}</span>}
            {this.props.reports &&
              this.props.reports
                .sort(function (a: any, b: any) {
                  return +new Date(b.createdAt) - +new Date(a.createdAt);
                })
                .map((gdprRequest: any) => {
                  if (gdprRequest.dataRequests) {
                    return gdprRequest.dataRequests.map((dataRequest: any) => {
                      const title = i18n('downloadReport.request') + ' ' + formatStringDate(gdprRequest.createdAt);
                      return (
                        <Expand
                          key={gdprRequest.uuid + dataRequest.type + dataRequest.parameter}
                          icon={expandIcon()}
                          title={titleArea(title)}
                          content={this._renderReport(gdprRequest, dataRequest)}
                        />
                      );
                    });
                  }
                })}
          </SpinnerWhenLoading>
        </BoxWithHeading>
      </MainContainer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    reports: state.reports.availableReports,
    token: state.login.token,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    fetchAvailableReports: () => {
      dispatch(createFetchDataFromBackendAction());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadReport);
