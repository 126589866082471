import * as React from 'react';
import { MouseEvent, PureComponent, ReactNode } from 'react';
import { AutoCol, Col } from '../grid';
import { Wrapper, ExpandButton, TitleRow, WithRightMargin, ExpandingContainer } from './Expand';

export type ExpandProps = {
  initallyOpen?: boolean;
  /**
   * The clickable area
   */
  title: ReactNode;
  /**
   * The expandable area
   */
  content: ReactNode;

  icon?: ReactNode;
  openIcon?: ReactNode;
  closedIcon?: ReactNode;
  name?: string;
  thingsChanged: Function;
};

export type ExpandState = {
  open: boolean;
  expandId: string;
};

let expandCounter = 0;
class ExpandWrapper extends PureComponent<ExpandProps, ExpandState> {
  constructor(props: ExpandProps) {
    super(props);
    this.state = {
      open: !!props.initallyOpen,
      expandId: `expand-${expandCounter++}`,
    };
  }

  render() {
    const { title, content, icon, openIcon, closedIcon } = this.props;
    const { expandId, open } = this.state;
    return (
      <Wrapper>
        <ExpandButton aria-controls={expandId} aria-expanded={open} onClick={this._toggle} type={'button'}>
          <TitleRow alignItems={'center'}>
            {icon && (
              <AutoCol>
                <WithRightMargin>{icon}</WithRightMargin>
              </AutoCol>
            )}
            {openIcon && closedIcon && !icon && (
              <AutoCol>
                <WithRightMargin>
                  {open && openIcon}
                  {!open && closedIcon}
                </WithRightMargin>
              </AutoCol>
            )}
            <Col>{title}</Col>
          </TitleRow>
        </ExpandButton>
        <ExpandingContainer id={expandId} role={'region'} tabIndex={-1} showBorder={open}>
          {open && content}
        </ExpandingContainer>
      </Wrapper>
    );
  }

  _toggle = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const nextStateForOpen = !this.state.open;
    this.setState({ open: nextStateForOpen });
    this.props.thingsChanged(nextStateForOpen);
  };
}

export const ReduxFormExpand = (props: any) => {
  const { input, ...rest } = props;
  return (
    <ExpandWrapper
      {...rest}
      initallyOpen={props.input.value}
      thingsChanged={(param: boolean) => {
        props.input.onChange(param);
      }}
    />
  );
};
