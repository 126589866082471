import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import styled from 'styled-components';
import { i18n } from '../../app/i18n';
import { RootState } from '../../app/state.interface';
import { OnlyForScreenReader } from '../../common/a11y';
import { Flag } from '../../common/flag';
import { FullWidth } from '../../common/layout';
import { safeGet } from '../../utils/safe-get';
import { AllowedFlag } from '../LanguageSwitcher/language.interface';
import { handleToggleLanguageSwitcher } from '../LanguageSwitcher/LanguageSwitcherActions';
import { LoginActions } from '../Login/LoginActions';
import { createLoginLink } from '../Login/LoginButton';
import { HeaderDiv, Item, Menu, Separator } from './view';

interface LoginItemProps {
  loggedIn: boolean;
  handleLogout: () => void;
  locale: string;
}

const unstyledButtonProps = (_props: any) => `
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
`;

export const UnstyledButton = styled.button`
  ${unstyledButtonProps};
`;
export const UnstyledLinkButton = styled.a`
  ${unstyledButtonProps};
  height: auto;
`;

export class LoginItem extends React.Component<LoginItemProps> {
  render() {
    const isLoggedIn = this.props.loggedIn;
    const handleClick = () => {
      this.props.handleLogout();
    };
    const { locale } = this.props;

    return (
      <Item className="loginStyle flex-auto">
        {isLoggedIn ? (
          <UnstyledButton onClick={handleClick}>
            <span className="loggedIn" />
            <span className="loginStatusText">{i18n('login.logoutStatus')}</span>
          </UnstyledButton>
        ) : (
          <UnstyledLinkButton href={createLoginLink(locale)}>
            <span className="loggedOut" />
            <span className="loginStatusText">{i18n('login.callToAction')}</span>
          </UnstyledLinkButton>
        )}
      </Item>
    );
  }
}

interface PropsFromState {
  loggedIn: boolean;
  currentLocale: string;
}

interface PropsFromDispatch {
  handleToggleLanguageSwitcher: () => void;
  handleLogout: () => void;
}

type Props = PropsFromState & PropsFromDispatch;

const mapStateToProps = (state: RootState): PropsFromState => ({
  loggedIn: !!safeGet(state, 'login', 'token'),
  currentLocale: state.language.currentLocale,
});

const mapDispatchToProps = (dispatch: any) => ({
  handleToggleLanguageSwitcher: () => dispatch(handleToggleLanguageSwitcher()),
  handleLogout: () => dispatch(LoginActions.logout(window)),
});

const INTERNATIONAL_FLAG: AllowedFlag = 'INT';
export const Header = (props: Props) => {
  const countryCode = props.currentLocale.split('_')[1] || INTERNATIONAL_FLAG;
  const languageCode = props.currentLocale.split('_')[0] || 'EN';
  return (
    <FullWidth>
      <HeaderDiv>
        <Menu>
          <Item className="home flex-auto">
            <a href="/">
              <UnstyledButton>
                <span className="homeIcon" />
                <span className="homeText">{i18n('home')}</span>
              </UnstyledButton>
            </a>
          </Item>
          <Item className="finnair-emblem flex-1">
            <a href="/" />
          </Item>
          <Item className="language flex-auto">
            <UnstyledButton onClick={props.handleToggleLanguageSwitcher}>
              <Flag flag={countryCode as AllowedFlag} />
              <span className="headerTextItem">
                {countryCode === 'FI' ? i18n('selectedCountry.finland') : i18n('selectedCountry.international')}
              </span>
              <span className="headerTextItem"> - </span>
              <span className="headerTextItem">{languageCode}</span>
              <OnlyForScreenReader>{i18n('changeLanguage')}</OnlyForScreenReader>
            </UnstyledButton>
          </Item>
          <LoginItem loggedIn={props.loggedIn} handleLogout={props.handleLogout} locale={props.currentLocale} />
        </Menu>
      </HeaderDiv>
      <Separator />
    </FullWidth>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
