import { FACCOUNT_MARKETING, FPLUS_MARKETING } from '../../app/constants';
import { Action } from '../../common/reducer-utils';
import { Dispatch } from 'redux';
import { safeGet } from '../../utils/safe-get';
import { RootState } from '../../app/state.interface';
import { ThunkAction } from 'redux-thunk';
import { CONFIG } from '../../app/config';
import ConsentItem = Components.Schemas.ConsentItemV2;
import { verifyTruthy } from '../../utils/verify';
import ConsigneeConsents = Components.Schemas.ConsigneeConsents;
import { CONSENT_STATUS, ConsentIdToBooleanMap } from '../../snippet/cookie-snippet.interface';
import { FinnairAccountType } from '../ExportData/exportData.interface';

export const TOGGLE_MARKETING_CONSENTS = 'TOGGLE_MARKETING_CONSENTS';
export const FETCH_CONSENT_INIT_DATA_START = 'FETCH_CONSENT_INIT_DATA_START';
export const FETCH_CONSENT_INIT_DATA_SUCCESS = 'FETCH_CONSENT_INIT_DATA_SUCCESS';
export const START_MANAGE_MARKETING_CONTENT_REQUEST = '[MarketingConsent] Start / put marketing consent request';
export const SUCCESS_MANAGE_MARKETING_CONTENT_REQUEST = '[MarketingConsent] Success / put marketing consent request';
export const FAILED_MANAGE_MARKETING_CONTENT_REQUEST = '[MarketingConsent] Failed / put marketing consent request';

export type MarketingConsentsActionTypes =
  | typeof TOGGLE_MARKETING_CONSENTS
  | typeof FETCH_CONSENT_INIT_DATA_START
  | typeof FETCH_CONSENT_INIT_DATA_SUCCESS
  | typeof START_MANAGE_MARKETING_CONTENT_REQUEST
  | typeof SUCCESS_MANAGE_MARKETING_CONTENT_REQUEST
  | typeof FAILED_MANAGE_MARKETING_CONTENT_REQUEST;
const CONSENT_API_ROOT_V2 = CONFIG.consentApiRootV2;

export function toggleMarketingConsent(itemId: string): ToggleMarketingConsentAction {
  return {
    type: TOGGLE_MARKETING_CONSENTS,
    payload: { itemId },
  };
}

type CreateFetchDataFromBackendAction = ThunkAction<Promise<void>, RootState, void, Action<any>>;

const getConsentGroup = (accountType: FinnairAccountType): string => {
  switch (accountType) {
    case FinnairAccountType.FACCOUNT:
      return FACCOUNT_MARKETING;
    case FinnairAccountType.FPLUS:
      return FPLUS_MARKETING;
    default:
      return 'UNKNOWN';
  }
};

async function getMarketingConsents(
  authToken: string,
  locale: string,
  accountType: FinnairAccountType
): Promise<ConsentItem[]> {
  const consentGroupString = getConsentGroup(accountType);

  const marketingConsentsUrl = `${CONSENT_API_ROOT_V2}/consents?locale=${encodeURIComponent(
    locale
  )}&consentGroup=${consentGroupString}`;

  const response = await fetch(marketingConsentsUrl, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + authToken,
    },
  });
  return await response.json();
}

export const fetchConsentInitDataStart = (): FetchConsentInitDataStartAction => ({
  type: FETCH_CONSENT_INIT_DATA_START,
  payload: undefined,
});

export const fetchConsentInitDataSuccess = (transformedConsents: ConsentItem): FetchConsentInitDataSuccessAction => ({
  type: FETCH_CONSENT_INIT_DATA_SUCCESS,
  payload: transformedConsents,
});

const extractLocale = (state: RootState): string => {
  const locale = safeGet(state, 'language', 'currentLocale') as string;
  return verifyTruthy(locale, 'Locale not found');
};

const extractAccessToken = (state: RootState): string => {
  const accessToken = safeGet(state, 'login', 'token');
  return verifyTruthy(accessToken, 'Light access failed: missing accessToken');
};

export function createFetchDataFromBackendAction(
  accountType: FinnairAccountType = FinnairAccountType.FPLUS
): CreateFetchDataFromBackendAction {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(fetchConsentInitDataStart());
    const locale = extractLocale(getState());
    const accessToken = extractAccessToken(getState());
    const consents: any = await getMarketingConsents(accessToken, locale, accountType);
    dispatch(fetchConsentInitDataSuccess(consents));
  };
}

export const startManageMarketingConsents = (): StartManageMarketingConsentRequestAction => ({
  type: START_MANAGE_MARKETING_CONTENT_REQUEST,
  payload: undefined,
});

export const manageMarketingConsentsSuccess = (): SuccessManageMarketingConsentRequestAction => ({
  type: SUCCESS_MANAGE_MARKETING_CONTENT_REQUEST,
  payload: undefined,
});

export const manageMarketingConsentsFailed = (): FailedManageMarketingConsentRequestAction => ({
  type: FAILED_MANAGE_MARKETING_CONTENT_REQUEST,
  payload: undefined,
});

const extractAllConsentsFromState = (state: RootState): any => {
  const allConsents = safeGet(state, 'checkedMarketingConsent', 'marketingConsentGroup');
  return verifyTruthy(allConsents, 'Consents not found');
};

const extractUserModifiedConsents = (state: RootState): ConsentIdToBooleanMap => {
  const userActedConsents = safeGet(state, 'checkedMarketingConsent', 'isChecked');
  return verifyTruthy(userActedConsents, 'User modified consents not found');
};
export const sendConsentsToApi = async (
  accessToken: string,
  consents: ConsigneeConsents,
  accountType: FinnairAccountType
): Promise<void> => {
  const url = `${CONFIG.consentApiRoot}/consents`;
  const consentGroupString = getConsentGroup(accountType);

  const apiResponse = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    credentials: 'include',
    body: JSON.stringify({
      consents: consents.consents,
      locale: consents.locale,
      source: document.location.hostname,
      consentGroup: consentGroupString,
    }),
  });
  if (apiResponse.status !== 204) {
    throw new Error(`Request failed: non-204 response from API: [${apiResponse.status}]`);
  }
};

export const createSubmitMarketingConsentsAction =
  (accountType: FinnairAccountType = FinnairAccountType.FPLUS): ThunkAction<void, RootState, void, Action<any>> =>
  async (dispatch: Dispatch<any>, getState: () => RootState) => {
    try {
      dispatch(startManageMarketingConsents());
      const locale = extractLocale(getState());
      const accessToken = extractAccessToken(getState());
      const getConsentsFromState = extractAllConsentsFromState(getState());
      const userModifiedConsents = extractUserModifiedConsents(getState());
      if (getConsentsFromState) {
        const consentItems = getConsentsFromState.consents.map((consentItem: ConsentItem) => ({
          ...consentItem,
          consentStatus: userModifiedConsents[consentItem.consentTextId]
            ? CONSENT_STATUS.accepted
            : CONSENT_STATUS.declined,
        }));
        const consentsToStore: ConsigneeConsents = {
          consents: consentItems,
          locale: locale,
          source: document.location.hostname,
        };
        await sendConsentsToApi(accessToken, consentsToStore, accountType);
        dispatch(manageMarketingConsentsSuccess());
      }
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error(err);
      dispatch(manageMarketingConsentsFailed());
    }
  };

export type ToggleMarketingConsentAction = Action<typeof TOGGLE_MARKETING_CONSENTS, { [key: string]: string }>;
export type FetchConsentInitDataStartAction = Action<typeof FETCH_CONSENT_INIT_DATA_START, void>;
export type FetchConsentInitDataSuccessAction = Action<typeof FETCH_CONSENT_INIT_DATA_SUCCESS, any>;
export type StartManageMarketingConsentRequestAction = Action<typeof START_MANAGE_MARKETING_CONTENT_REQUEST, void>;
export type SuccessManageMarketingConsentRequestAction = Action<typeof SUCCESS_MANAGE_MARKETING_CONTENT_REQUEST, void>;
export type FailedManageMarketingConsentRequestAction = Action<typeof FAILED_MANAGE_MARKETING_CONTENT_REQUEST, void>;
export type HandleMarketingConsentsChange = typeof createSubmitMarketingConsentsAction;
