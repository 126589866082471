import * as React from 'react';

import styled from 'styled-components';
import { color, spacing, font } from '../../assets/styles';

const SwitchContainer = styled.span`
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-left: 2rem;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${color.gray600};
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: ${color.blue};
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .selected input + .slider {
    background-color: ${color.gray200};
  }

  :focus-within {
    outline: ${color.blue} solid 2px;
  }
`;

const Label = styled.label`
  display: flex;
  margin: ${spacing.xxSmall} 0 ${spacing.xSmall} 0;
`;

const Paragraph = styled.p`
  display: inline;
  font-family: FinnairSans;
  font-size: ${font.smallPlus};
  line-height: ${font.regular};
  color: ${color.black};
  margin-right: 1rem;
`;

const InlineWithInlineChildren = styled.div`
  display: inline;
  font-size: ${font.smallPlus};
  line-height: ${font.regular};
  margin: ${spacing.xxSmall} 0 ${spacing.xSmall} 0;
  width: 90%;

  > * {
    display: inline;
  }
`;

export enum ToggleSwitchStatus {
  UNCHECKED = 'UNCHECKED',
  CHECKED = 'CHECKED',
  ACTIVE = 'ACTIVE',
}

interface Props {
  id: string;
  label: string;
  status: () => ToggleSwitchStatus;
  handleCheckboxChange: (id: string) => void;
  htmlContent?: string;
}

class ToggleSwitch extends React.Component<Props> {
  render() {
    const { label, status, handleCheckboxChange, id } = this.props;
    return (
      <SwitchContainer>
        <Label className={status() === ToggleSwitchStatus.CHECKED ? 'selected' : ''}>
          {this.props.htmlContent ? (
            <InlineWithInlineChildren dangerouslySetInnerHTML={{ __html: this.props.htmlContent }} />
          ) : (
            <Paragraph>{label}</Paragraph>
          )}
          <label className="switch">
            <input
              type="checkbox"
              onChange={() => handleCheckboxChange(id)}
              checked={status() === ToggleSwitchStatus.ACTIVE || status() === ToggleSwitchStatus.CHECKED || false}
            />
            <span className="slider round" />
          </label>
        </Label>
      </SwitchContainer>
    );
  }
}

export default ToggleSwitch;
