import * as React from 'react';
import styled from 'styled-components';
import { breakPoint, color, spacing, value } from '../../assets/styles';
import { i18n } from '../../app/i18n';

const finnair = require('../../assets/images/finnair-emblem.svg');

const StyledFooter = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  background: ${color.white};

  .finnair-footer-logo {
    height: ${spacing.small};
    width: ${spacing.xLarge};
    margin-bottom: ${spacing.small};
    background: url('${finnair}') no-repeat center;
    color: ${color.blue};
  }

  a {
    text-decoration: none;
    color: ${color.blue};
  }
`;

const FooterWrapper = styled.div`
  width: 100%;
  max-width: ${value.appWrapperMaxWidth};
  margin: 0 auto;
`;

const StyledList = styled.ul`
  list-style: none;
  line-height: 1.2rem;
  color: ${color.darkGray};
  font-size: 14px;
  padding: ${spacing.large} ${spacing.medium};
  margin: 0;

  @media (min-width: ${breakPoint.largeScreenMin}) {
    margin-left: 0;
    padding: ${spacing.large} 0
`;

class Footer extends React.Component {
  getYear() {
    return new Date().getFullYear();
  }
  render() {
    return (
      <StyledFooter>
        <FooterWrapper>
          <StyledList>
            <li className="finnair-footer-logo" />
            <li>
              <div>© {this.getYear()} Finnair</div>
            </li>
            <li>
              <a dangerouslySetInnerHTML={{ __html: i18n('footer.finnairPolicy') }} />
            </li>
            <li>
              <a dangerouslySetInnerHTML={{ __html: i18n('footer.aurinkomatkatPolicy') }} />
            </li>
          </StyledList>
        </FooterWrapper>
      </StyledFooter>
    );
  }
}

export default Footer;
