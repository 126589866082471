import * as React from 'react';
import styled from 'styled-components';
import { color } from '../../assets/styles';

export const ShowSuccessMessage = styled.div`
  position: absolute;
  bottom: -3rem;
  left: 0;
  right: 0;
  background: ${color.lighterBlue};
  text-align: center;
  line-height: 2.5;
  overflow: hidden;
  animation-name: slideUpDown;
  animation-delay: 0.3s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;

  @keyframes slideUpDown {
    0%,
    100% {
      bottom: -3rem;
    }
    10%,
    90% {
      bottom: 0;
    }
  }
`;

interface FlashMessageProps {
  visible: boolean;
  message: string;
}

class FlashMessage extends React.Component<FlashMessageProps> {
  render() {
    const { visible, message } = this.props;
    return visible ? <ShowSuccessMessage>{message}</ShowSuccessMessage> : null;
  }
}
export default FlashMessage;
