import { Dispatch } from 'redux';
import { CONFIG } from '../../app/config';
import { RootState } from '../../app/state.interface';
import { Action } from '../../common/reducer-utils';
import { LOGIN_TOKEN_STORAGE_KEY } from './login.interface';
import { safeRemoveFromStorage } from './utils';

export const STORE_LOGIN_TOKEN = '[Login] store token';

export type LoginActionTypes = typeof STORE_LOGIN_TOKEN;

const delay = (t: number) => new Promise((resolve) => setTimeout(resolve, t));

export class LoginActions {
  static storeToken = (token: string): StoreLoginTokenAction => ({
    type: STORE_LOGIN_TOKEN,
    payload: token,
  });

  static logout = (_window: Window) => async (dispatch: Dispatch<any>) => {
    dispatch(LoginActions.clearToken());
    delay(200).then(() => {
      safeRemoveFromStorage(LOGIN_TOKEN_STORAGE_KEY, _window.sessionStorage);
      _window.location.href = `${CONFIG.authHost}/cas/logout?service=${_window.location.href}`;
    });
  };

  static clearToken = (): ClearLoginTokenAction => ({
    type: STORE_LOGIN_TOKEN,
    payload: undefined,
  });
}

export type StoreLoginTokenAction = Action<typeof STORE_LOGIN_TOKEN, string>;

export type ClearLoginTokenAction = Action<typeof STORE_LOGIN_TOKEN>;
