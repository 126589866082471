import * as React from 'react';
import { StatelessComponent } from 'react';
import styled from 'styled-components';
import { color, spacing, value } from '../../../assets/styles';

const ModalOverlay = styled.div`
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(30, 30, 30, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalWindow = styled.div`
  position: fixed;
  box-sizing: border-box;
  max-width: calc(100vw - 2 * ${spacing.small});
  margin: auto;
  padding: ${spacing.small};
  background-color: white;
  border: 1px solid ${color.lightGray};
  border-radius: ${value.rounding};
  @media (min-width: 42rem) {
    max-width: 40rem;
  }
`;

export const Modal: StatelessComponent<{}> = ({ children }) => (
  <ModalOverlay>
    <ModalWindow>{children}</ModalWindow>
  </ModalOverlay>
);
