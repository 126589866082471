import * as React from 'react';
import styled from 'styled-components';
import { PureComponent } from 'react';
import { spacing } from '../../assets/styles';
import { PrimaryLinkLink } from '../../common/buttons';
import { TextContainer } from '../../common/layout';

export type NonLoggedInActionsProps = {
  loggedIn: boolean;
  descriptionLabel: string;
  buttonText: string;
  targetUri: string;
};

const ButtonWrapper = styled.span`
  display: inline-block;
  padding-top: ${spacing.xSmall};
`;

export class NonLoggedInActions extends PureComponent<NonLoggedInActionsProps> {
  render() {
    const { loggedIn, descriptionLabel, buttonText, targetUri } = this.props;
    if (!loggedIn) {
      return (
        <ButtonWrapper>
          <span>{descriptionLabel}</span>
          <TextContainer>
            <PrimaryLinkLink href={targetUri}>{buttonText}</PrimaryLinkLink>
          </TextContainer>
        </ButtonWrapper>
      );
    } else {
      return null;
    }
  }
}
