import * as React from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';
import { SectionWithoutBorder } from '../Containers';
import { TextInput } from '../../../../common/reduxForms';
import * as validation from '../validations';
import { Title } from '../Texts';
import { color, font } from '../../../../assets/styles';
import { Col, Row } from '../../../../common/grid';
import { i18n } from '../../../../app/i18n';

export type BaseProps = {
  titleColor: string;
  isFileUploadRequired: boolean;
};

export const ErrorSpan = styled.div`
  font-size: ${font.smallPlus};
  font-weight: 600;
  color: ${color.errorRed};
  padding-bottom: 20px;
  text-align: right;
`;

export const BaseCopyOfPassportList = styled.ul`
  padding-left: 20px;
  margin-bottom: 0;
`;

export const BaseFields = (props: BaseProps) => {
  return (
    <SectionWithoutBorder>
      <Title titleColor={props.titleColor}>{i18n('exportDataScreen.baseTitle')}</Title>
      <Row>
        <Col>
          <Field
            name="firstName"
            component={TextInput}
            label={i18n('exportDataScreen.baseFirstName')}
            validate={[validation.required, validation.notNumbers]}
          />
        </Col>
        <Col>
          <Field
            name="lastName"
            component={TextInput}
            label={i18n('exportDataScreen.baseFamilyName')}
            validate={[validation.required, validation.notNumbers]}
          />
        </Col>
      </Row>

      <Field
        name="address"
        component={TextInput}
        label={i18n('exportDataScreen.baseAddress')}
        validate={[validation.required]}
      />

      <Row>
        <Col>
          <Field
            name="email"
            component={TextInput}
            label={i18n('exportDataScreen.baseEmail')}
            validate={[validation.required]}
          />
        </Col>
        <Col>
          <Field
            name="phoneNumber"
            component={TextInput}
            label={i18n('exportDataScreen.basePhoneNumber')}
            validate={[validation.required]}
          />
        </Col>
      </Row>

      <Field
        name="otherContactDetails"
        component={TextInput}
        label={i18n('exportDataScreen.baseOtherContactDetails')}
        validate={[]}
      />
    </SectionWithoutBorder>
  );
};
