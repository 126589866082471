import { FACCOUNT_MARKETING, FPLUS_MARKETING } from '../../app/constants';
import { MarketingConsentState } from './marketingConsent.interface';
import {
  FAILED_MANAGE_MARKETING_CONTENT_REQUEST,
  FailedManageMarketingConsentRequestAction,
  FETCH_CONSENT_INIT_DATA_START,
  FETCH_CONSENT_INIT_DATA_SUCCESS,
  FetchConsentInitDataSuccessAction,
  MarketingConsentsActionTypes,
  START_MANAGE_MARKETING_CONTENT_REQUEST,
  SUCCESS_MANAGE_MARKETING_CONTENT_REQUEST,
  SuccessManageMarketingConsentRequestAction,
  TOGGLE_MARKETING_CONSENTS,
  ToggleMarketingConsentAction,
} from './MarketingConsentActions';
import { createReducer } from '../../common/reducer-utils';
import { CONSENT_STATUS } from '../../snippet/cookie-snippet.interface';

const initialState: MarketingConsentState = {
  isChecked: {},
  canProceedFurther: true,
  changesSaved: false,
};

const MARKETING_CONSENT_GROUPS = [FPLUS_MARKETING, FACCOUNT_MARKETING];

const isMarketingConsentGroup = (consentGroup: string): boolean => {
  return MARKETING_CONSENT_GROUPS.indexOf(consentGroup) !== -1;
};

export const marketingConsentReducer = createReducer<MarketingConsentState, MarketingConsentsActionTypes>(
  initialState,
  {
    [TOGGLE_MARKETING_CONSENTS]: (state: MarketingConsentState, action: ToggleMarketingConsentAction) => {
      const newMarketingConsent = { ...state.isChecked };
      newMarketingConsent[action.payload.itemId] = !state.isChecked[action.payload.itemId];
      return {
        ...state,
        isChecked: newMarketingConsent,
      };
    },
    [FETCH_CONSENT_INIT_DATA_START]: (state) => ({ ...state, loading: true }),
    [FETCH_CONSENT_INIT_DATA_SUCCESS]: (state, action: FetchConsentInitDataSuccessAction) => {
      const marketingConsents = action.payload.filter((item: any) => isMarketingConsentGroup(item.consentGroup));
      return {
        ...state,
        marketingConsentGroup: marketingConsents[0],
        loading: false,
        isChecked: {
          ...marketingConsents[0].consents.reduce((acc: any, consent: any) => {
            return {
              ...acc,
              [consent.consentTextId]: consent.consentStatus === CONSENT_STATUS.accepted,
            };
          }, {}),
        },
      };
    },
    [START_MANAGE_MARKETING_CONTENT_REQUEST]: (state) => ({
      ...state,
      canProceedFurther: false,
      changesSaved: false,
    }),
    [SUCCESS_MANAGE_MARKETING_CONTENT_REQUEST]: (state, action: SuccessManageMarketingConsentRequestAction) => {
      return {
        ...state,
        canProceedFurther: true,
        changesSaved: true,
      };
    },
    [FAILED_MANAGE_MARKETING_CONTENT_REQUEST]: (state, action: FailedManageMarketingConsentRequestAction) => ({
      ...state,
    }),
  }
);
