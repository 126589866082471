import * as querystring from 'querystring';
import { getFirstValue } from '../utils/first-value';
import { getInArray } from '../utils/safe-get';
import { TRANSLATIONS } from './translations';

interface IndexedArray {
  [key: string]: string | number;
}
export type ReplaceParams = IndexedArray | { [key: string]: string };
export const DEFAULT_UI_LOCALE = 'en';
export const ALLOWED_LOCALES = Object.keys(TRANSLATIONS);

if (!sessionStorage.getItem('locale')) {
  sessionStorage.setItem('locale', DEFAULT_UI_LOCALE);
}

const replaceMsgFormat = (input: any, args: any = []): string => {
  if (typeof input !== 'string') {
    return input;
  }
  let result = input;
  Object.keys(args).forEach((key: keyof ReplaceParams) => {
    const regex = new RegExp(`\\{${key}\\}`, 'g');
    result = result.replace(regex, args[key]);
  });
  return result;
};

export function setGlobalUiLocale(locale: string) {
  sessionStorage.setItem('locale', locale);
}

export function setGlobalUiLocaleFromQueryString(queryString: string = '') {
  const parsedUrlQuery = querystring.parse(queryString.replace(/^\?/, ''));
  const locale = getFirstValue(parsedUrlQuery.locale);

  if (!!locale && ALLOWED_LOCALES.indexOf(locale) !== -1) {
    sessionStorage.setItem('locale', locale);
  }
}

export function getGlobalUiLocale() {
  return sessionStorage.getItem('locale') || DEFAULT_UI_LOCALE;
}

export const i18n = function (msgKey: string, ...args: any[]) {
  return replaceMsgFormat(getInArray(TRANSLATIONS, [getGlobalUiLocale(), ...msgKey.trim().split('.')]), args);
};
