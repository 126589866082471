import * as React from 'react';
import { ChangeEvent, Component, FocusEvent, LabelHTMLAttributes } from 'react';
import styled from 'styled-components';
import { breakPoint, color, font, spacing } from '../assets/styles';
import { Centered } from './layout';

type LabelProps = { moveLabelOnTopOfField: boolean } & LabelHTMLAttributes<HTMLLabelElement>;

export const INPUT_HEIGHT = '1.25rem';

export const FieldWrapper = styled.div``;

export const FieldContainer = styled.fieldset`
  position: relative;
  display: block;
  border: 0;
  padding: ${spacing.smallPlus} 0;
  text-align: left;
  max-width: 100%;
`;

export const LabelSpan = styled.div<LabelProps>`
  position: absolute;
  top: ${spacing.smallPlus};
  font-size: ${(props) => (props.moveLabelOnTopOfField ? font.regular : font.smallPlus)};
  transform: translateY(${(props) => (props.moveLabelOnTopOfField ? '0' : '-1rem')});
  transition: 0.3s all;
`;

export const HintSpan = styled.div`
  font-size: ${font.smallPlus};
  color: ${color.darkGray};
  padding-bottom: 20px;
  text-align: right;
`;

export const ErrorSpan = styled.div`
  font-size: ${font.smallPlus};
  font-weight: 600;
  color: ${color.errorRed};
  padding-bottom: 20px;
  text-align: left;
`;

const labelZIndex = 1;
export const Label = styled.label`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: ${labelZIndex};

  display: block;
  color: ${color.darkGray};

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 1rem;
    pointer-events: none;
    transition:
      0.3s width ease,
      0.3s background-color ease;
  }
  &:before {
    width: 100%;
    height: 1px;
    background-color: ${color.lightGray};
  }
  &:after {
    height: 3px;
    width: 0;
    background-color: ${color.blue};
    input.visited + & {
      width: 100%;
    }
    input:focus + & {
      background-color: ${color.blue};
    }
    input.visited:invalid:not(:focus) + & {
      background-color: ${color.heather};
    }
    input.error:not(:focus) + & {
      background-color: ${color.errorRed};
    }
  }
`;

export const InputField = styled.input`
  /* Stay above label for mouse accessibility */
  position: relative;
  z-index: ${labelZIndex + 1};
  /* /Stay above label for mouse accessibility */
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  font-size: 1rem;
  line-height: 0.8125;
  height: ${INPUT_HEIGHT};
  color: ${color.charcoal};
  background-color: transparent;
  transition: 0.3s all;
`;

export type InputGroupProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  name?: string;
  hint?: string;
  validate?: Function;
};
export type InputGroupState = {
  fieldId: string;
  hasFocus: boolean;
  visited: boolean;
  fieldValue: string;
  error: string;
};
let inputCounter: number = 0;
export class InputGroup extends Component<InputGroupProps, InputGroupState> {
  private _field: HTMLInputElement;
  constructor(props: InputGroupProps) {
    super(props);
    this.state = {
      hasFocus: false,
      visited: false,
      fieldId: `input-${inputCounter++}`,
      fieldValue: '',
      error: '',
    };
  }

  render() {
    const { onFocus, onBlur, onChange, value, label, hint, ...rest } = this.props;
    const { fieldId, hasFocus, fieldValue, visited, error } = this.state;
    const classNames = [];
    if (visited) {
      classNames.push('visited');
    }
    if (error) {
      classNames.push('error');
    }
    const classNamesAsString = classNames.join(' ');
    return (
      <FieldWrapper>
        <FieldContainer className={classNamesAsString}>
          <InputField
            id={fieldId}
            value={fieldValue}
            ref={(ref: HTMLInputElement) => (this._field = ref)}
            onFocus={this._handleFocus}
            onBlur={this._handleBlur}
            onChange={this._handleChange}
            className={classNamesAsString}
            {...rest}
          />
          <Label htmlFor={fieldId}>
            <LabelSpan moveLabelOnTopOfField={!hasFocus && !fieldValue}>{label}</LabelSpan>
          </Label>
        </FieldContainer>
        {hint && <HintSpan>{hint}</HintSpan>}
        {this.state.error && <ErrorSpan>{this.state.error}</ErrorSpan>}
      </FieldWrapper>
    );
  }

  get value(): string {
    return this.state.fieldValue;
  }

  get name(): string {
    if (!this.props.name) {
      return '';
    }
    return this.props.name;
  }

  get valid(): boolean {
    if (this.props.required && !this.state.fieldValue) {
      return false;
    }
    return this.state.error ? false : true;
  }

  _handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      fieldValue: event.target.value,
    });
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  };

  _handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    this.setState({ hasFocus: true, visited: true });
    if (!!this.props.onFocus) {
      this.props.onFocus(event);
    }
  };

  _handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (this.props.validate) {
      try {
        this.props.validate(event.target.value);
      } catch (error) {
        //console.log('NOT VALID', error);
        this.setState({
          error: error,
        });
        return;
      }
    }
    this.setState({
      hasFocus: false,
      error: '',
    });
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  };
}

/*
 * Ugly hack for getting the form to retain the correct properties
 * for the `form` tag when extending the component styles using `withComponent`.
 *
 * See https://github.com/styled-components/styled-components/issues/1315
 */
const form = styled.form``;
export const CenteredForm = styled(Centered.withComponent('form'))`
  flex-flow: column wrap;
  align-items: center;
  max-width: 30rem;
  margin: ${spacing.xLarge} auto;
  @media (max-width: ${breakPoint.mobileMax}) {
    margin: ${spacing.small};
  }
` as typeof form;
