import { ReactNode } from 'react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { color, breakPoint, lineHeight, spacing, value, font, weight } from '../../assets/styles';
import { Icon } from '../../common/Icon';

export const ContentMenu = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  max-width: ${value.pageContentWidth};
  margin: 0 auto;
`;

export const ContentMenuItemWrapper = styled.li`
  display: flex;
  align-items: center;
  border: 1px solid ${color.lightGray};
  border-radius: ${value.rounding};
  box-sizing: border-box;
  margin: ${value.margin};
  background: ${color.white};
`;

const ItemBody = styled.div`
  display: block;
  flex: 1 0 0;
  padding: ${spacing.small};
  text-decoration: none;
`;

const ContentMenuTitle = styled.div`
  font-size: ${font.regularPlus};
  font-weight: ${weight.bold};
  color: ${color.black};
`;

const ContentMenuDescription = styled.div`
  font-size: ${font.smallPlus};
  line-height: ${lineHeight.medium};
  color: ${color.black};
  text-transform: none;
  margin-top: ${spacing.xxSmall};
`;

const IconWrapper = styled.div`
  justify-content: center;
  align-items: center;

  @media (max-width: ${breakPoint.mobileMax}) {
    &:last-of-type {
      margin-bottom: ${spacing.small};
    }
    &:first-of-type {
      margin-top: ${spacing.small};
    }
  }

  @media (min-width: ${breakPoint.desktopMin}) {
    flex: 0 0 4.5rem;
    display: flex;
    margin-top: 0;

    &:first-of-type {
      margin-left: ${spacing.smallPlus};
    }
  }
`;

const ContentMenuLink = styled(Link)`
  width: 100%;
  min-height: 4.5rem;
  text-decoration: none;
  color: ${color.blue};

  @media (max-width: ${breakPoint.mobileMax}) {
    text-align: center;
  }

  @media (min-width: ${breakPoint.desktopMin}) {
    display: flex;
  }
`;

type ContentMenuItemProps = {
  title: string;
  description: string;
  href: string;
  icon: ReactNode;
};

export const ContentMenuItem = ({ title, description, href, icon }: ContentMenuItemProps) => (
  <ContentMenuItemWrapper>
    <ContentMenuLink to={href}>
      <IconWrapper>{icon}</IconWrapper>
      <ItemBody>
        <ContentMenuTitle>{title}</ContentMenuTitle>
        <ContentMenuDescription>{description}</ContentMenuDescription>
      </ItemBody>
      <IconWrapper>
        <Icon name={'arrowRight'} size={'medium'} />
      </IconWrapper>
    </ContentMenuLink>
  </ContentMenuItemWrapper>
);
