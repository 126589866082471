import { createReducer } from '../../common/reducer-utils';
import { ExportDataState, ExportDataStatus } from './exportData.interface';
import {
  CLEAR_BOOKING_FORM_VALUES,
  DataAccessRequestActionTypes,
  FAILED_LIGHT_DATA_REQUEST,
  RESET_EXPORT_DATA,
  SET_BOOKING_FORM_VALUES,
  SetBookingFormValuesAction,
  START_LIGHT_DATA_REQUEST,
  SUCCESS_LIGHT_DATA_REQUEST,
  SuccessLightAccessRequestAction,
  SUCCESS_PROFILE_REQUEST,
} from './ExportDataActions';

export const INITIAL_STATE: ExportDataState = {
  status: ExportDataStatus.INITIAL,
};

export const exportDataReducer = createReducer<ExportDataState, DataAccessRequestActionTypes>(INITIAL_STATE, {
  [START_LIGHT_DATA_REQUEST]: () => ({ status: ExportDataStatus.PROCESSING }),
  [FAILED_LIGHT_DATA_REQUEST]: () => ({ status: ExportDataStatus.FAILED }),

  [SUCCESS_LIGHT_DATA_REQUEST]: (
    state: ExportDataState,
    { payload }: SuccessLightAccessRequestAction
  ): ExportDataState => ({
    status: ExportDataStatus.SUCCESS,
    requestId: payload,
    profile: undefined,
  }),
  [SET_BOOKING_FORM_VALUES]: (state: ExportDataState, action: SetBookingFormValuesAction): ExportDataState => ({
    ...state,
    pnrFormValues: action.payload,
  }),

  [SUCCESS_PROFILE_REQUEST]: (state: ExportDataState, { payload }) => ({
    profile: payload,
  }),
  [CLEAR_BOOKING_FORM_VALUES]: ({ pnrFormValues, ...rest }: ExportDataState): ExportDataState => rest,
  [RESET_EXPORT_DATA]: () => INITIAL_STATE,
});
