import { ConsentIdToBooleanMap } from './snippet/cookie-snippet.interface';
import { hyphenate } from './utils/hyphenate';

export type DataLayer = Array<any>;

declare global {
  interface Window {
    dataLayer: DataLayer;
  }
}

const pushToDataLayer = (dataLayerObject: Object) => {
  if (!window.dataLayer || !window.dataLayer.push) {
    console.warn('window.dataLayer or window.datalayer.push does not exist');
    return;
  }

  window.dataLayer.push(dataLayerObject);
};

export type elementActions = 'click' | 'submit';

export type elementTypes = 'button' | 'radio' | 'form';

export const signalLoadedConsents = (consentMap: ConsentIdToBooleanMap) => {
  const dataLayerObject = {
    event: 'consentsLoaded',
    consentsLoaded: consentMap,
  };

  pushToDataLayer(dataLayerObject);
};

export const signalUpdatedConsents = (consentMap: ConsentIdToBooleanMap) => {
  const dataLayerObject = {
    event: 'consentsUpdated',
    consentsUpdated: consentMap,
  };

  pushToDataLayer(dataLayerObject);
};

export const pageView = (path: string) => {
  const dataLayerObject = {
    event: 'pageView',
    pageView: {
      path,
    },
  };

  pushToDataLayer(dataLayerObject);
};

export const uiEvent = (
  eventCategory: string,
  eventAction: elementActions,
  eventLabel: string,
  elementType: elementTypes
): void => {
  eventLabel = hyphenate(eventLabel);

  const dataLayerObject = {
    event: 'uiEvent',
    uiEvent: {
      eventCategory,
      eventAction,
      eventLabel,
      elementType,
    },
  };

  pushToDataLayer(dataLayerObject);
};
