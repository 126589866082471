import { ReactNode, StatelessComponent } from 'react';
import styled from 'styled-components';
import { spacing, breakPoint } from '../../assets/styles';
import { H2 } from '../type';
import * as React from 'react';

const MediaContainer = styled.div`
  margin: ${spacing.smallPlus} ${spacing.large};

  @media (min-width: ${breakPoint.desktopMin}) {
    display: flex;
    flex-flow: row nowrap;
  }
`;
const MediaIcon = styled.div`
  @media (min-width: ${breakPoint.desktopMin}) {
    flex: 0 0 3rem;
    padding-right: ${spacing.smallPlus};
  }
`;
const MediaContent = styled.div`
  @media (min-width: ${breakPoint.desktopMin}) {
    flex: 1 0 0;
    text-align: left;
  }
`;

export const MediaTitle = styled(H2)`
  margin: 0 0 ${spacing.xxSmall};
  @media (max-width: ${breakPoint.mobileMax}) {
    padding-top: ${spacing.small};
  }
`;

type MediaItemProps = {
  media: ReactNode;
};

export const MediaItem: StatelessComponent<MediaItemProps> = ({ media, children }) => (
  <MediaContainer>
    <MediaIcon>{media}</MediaIcon>
    <MediaContent>{children}</MediaContent>
  </MediaContainer>
);
