import * as React from 'react';
import { FormEvent } from 'react';
import { Centered, MainContainer } from '../../common/layout';
import HeroImage from '../HeroImage';
import { RoundedButton, RoundedButtonWhite } from '../../common/buttons';
import { i18n } from '../../app/i18n';
import { Paragraph } from '../MarketingConsent/_view';
import { ReturnedConsents, ConsentItemWithText } from '../../snippet/cookie-snippet.interface';
import { SpinnerWhenLoading } from '../Spinner/index';
import { ConsentIntro, Fieldset } from './view';
import FlashMessage from '../../common/FlashMessage/FlashMessage';
import { uiEvent } from '../../dataLayer';
import ToggleSwitch, { ToggleSwitchStatus } from '../../common/ToggleButton/ToggleSwitch';
import { Icon } from '../../common/Icon';
import styled from 'styled-components';
import { Heading } from '../../common/Heading';

const ConsentsContainer = styled(MainContainer)`
  font-family: 'FinnairSans';
`;

const InfoText = styled.span`
  text-align: left;
  font-size: 14px;
  padding-left: 16px;
`;

const IconWrapper = styled.div`
  margin-top: 8px;
  display: table;

  > * {
    display: table-cell;
    vertical-align: middle;
  }
`;
interface CookieConsentPageProps {
  selectedConsentIds: string[];
  consentPageData: ReturnedConsents;
  showSpinner: boolean;
  buttonStatus: boolean;
  cookiesSavedSuccessfully: boolean;
  handleToggleCookieConsents: (itemId: string) => void;
  handleToggleAllCookieConsents: () => void;
  handleSubmitCookieConsents: () => void;
  fetchConsentsInitData: () => void;
}

export const CookieConsentPage = ({
  selectedConsentIds,
  consentPageData,
  showSpinner,
  buttonStatus,
  cookiesSavedSuccessfully,
  handleToggleCookieConsents,
  handleToggleAllCookieConsents,
  handleSubmitCookieConsents,
}: CookieConsentPageProps) => {
  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    uiEvent('cookie-consents', 'submit', 'save-cookie-consents', 'form');
    handleSubmitCookieConsents();
  };

  const allowAll = () => {
    if (!consentPageData?.consents) {
      return;
    }
    handleToggleAllCookieConsents();
  };

  const getStatus = (
    consentIds: string[],
    consentItem: ConsentItemWithText,
    consents: ConsentItemWithText[]
  ): ToggleSwitchStatus => {
    // always set the first cookie consent as default
    if (consentItem.consentTextId === consents[0].consentTextId) {
      return ToggleSwitchStatus.CHECKED;
    }

    if (consentIds.includes(consentItem.consentTextId)) {
      return ToggleSwitchStatus.ACTIVE;
    }

    return ToggleSwitchStatus.UNCHECKED;
  };

  if (!consentPageData) {
    return null;
  }

  return (
    <ConsentsContainer>
      <HeroImage
        title={i18n('cookieConsentPage.heroImage.title')}
        ingress={i18n('cookieConsentPage.heroImage.ingress')}
      />
      <Heading title={i18n('cookieConsentPage.title')}>
        <SpinnerWhenLoading loading={showSpinner}>
          <ConsentIntro dangerouslySetInnerHTML={{ __html: i18n('cookieConsentPage.intro') }} />
          <form onSubmit={handleFormSubmit}>
            <Fieldset>
              {consentPageData.consents.map((consentItem) => (
                <ToggleSwitch
                  id={consentItem.consentTextId}
                  key={consentItem.consentTextId}
                  label={consentItem.text || ''}
                  htmlContent={consentItem.text}
                  status={() => getStatus(selectedConsentIds, consentItem, consentPageData.consents)}
                  handleCheckboxChange={handleToggleCookieConsents}
                />
              ))}
            </Fieldset>

            <Paragraph dangerouslySetInnerHTML={{ __html: consentPageData.afterwordText }} />

            <Centered>
              <RoundedButton disabled={!buttonStatus} type="submit">
                {i18n('cookieConsentPage.saveBtn')}
              </RoundedButton>
            </Centered>

            <Centered>
              <RoundedButtonWhite disabled={!buttonStatus} onClick={() => allowAll()}>
                {i18n('cookieConsentPage.allowAllBtn')}
              </RoundedButtonWhite>
            </Centered>

            <IconWrapper>
              <Icon name={'info'} size={'medium'} display={'inline-block'} margin={'0 1rem -0.4rem 0'} />
              <InfoText className={'notification'}>{i18n('cookieConsentPage.cookiesTips')}</InfoText>
            </IconWrapper>
          </form>
        </SpinnerWhenLoading>
        <FlashMessage visible={cookiesSavedSuccessfully} message={i18n('cookieConsentPage.changesSaved')} />
      </Heading>
    </ConsentsContainer>
  );
};
