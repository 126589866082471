import * as React from 'react';
import { HTMLAttributes, MouseEvent, PureComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { spacing, color } from '../../assets/styles';
import { AutoCol, Col, Row } from '../grid';
import { Icon } from '../Icon';

export type ExpandProps = {
  initallyOpen?: boolean;
  /**
   * The clickable area
   */
  title: ReactNode;
  /**
   * The expandable area
   */
  content: ReactNode;

  icon?: ReactNode;
  openIcon?: ReactNode;
  closedIcon?: ReactNode;
  name?: string;
  closeCallback?: Function;
};

export type ExpandState = {
  open: boolean;
  expandId: string;
};

const borderWidth = '1px';
export const Wrapper = styled.div`
  width: 100%;
  border-bottom: ${borderWidth} solid ${color.lightGray};
`;

export const ExpandButton = styled.button`
  -webkit-appearance: none;
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  background-color: white;
  text-align: left;
`;

export const WithRightMargin = styled.div`
  margin-right: 10px;
`;

type ExpandingContainerProps = HTMLAttributes<HTMLDivElement> & {
  showBorder: boolean;
};
export const ExpandingContainer = styled.div<ExpandingContainerProps>``;

export const TitleRow = styled(Row)`
  padding-top: ${spacing.medium};
  padding-bottom: ${spacing.medium};
`;

let expandCounter = 0;
export class Expand extends PureComponent<ExpandProps, ExpandState> {
  constructor(props: ExpandProps) {
    super(props);
    this.state = {
      open: !!props.initallyOpen,
      expandId: `expand-${expandCounter++}`,
    };
  }

  render() {
    const { title, content, icon, openIcon, closedIcon } = this.props;
    const { expandId, open } = this.state;
    return (
      <Wrapper>
        <ExpandButton aria-controls={expandId} aria-expanded={open} onClick={this._toggle} type={'button'}>
          <TitleRow alignItems={'center'}>
            {icon && (
              <AutoCol>
                <WithRightMargin>{icon}</WithRightMargin>
              </AutoCol>
            )}
            {openIcon && closedIcon && !icon && (
              <AutoCol>
                <WithRightMargin>
                  {open && openIcon}
                  {!open && closedIcon}
                </WithRightMargin>
              </AutoCol>
            )}
            <Col>{title}</Col>
            <AutoCol>
              <Icon name={'chevronDown'} size={'medium'} rotate180={open} />
            </AutoCol>
          </TitleRow>
        </ExpandButton>
        <ExpandingContainer id={expandId} role={'region'} tabIndex={-1} showBorder={open}>
          {open && content}
        </ExpandingContainer>
      </Wrapper>
    );
  }

  _toggle = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const nextStateForOpen = !this.state.open;
    this.setState({ open: nextStateForOpen });
    if (!nextStateForOpen && this.props.closeCallback) {
      this.props.closeCallback(this.props.name);
    }
  };

  get checked(): boolean {
    return this.state.open;
  }

  get name(): string {
    if (this.props.name) {
      return this.props.name;
    }
    return '';
  }
}
