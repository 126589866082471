import { CountryLanguage, Language } from './language.interface';

export const languages: Language[] = [
  {
    name: 'Finland',
    code: 'FI',
    languages: [{ name: 'Suomeksi', code: 'fi_FI' }],
  },
  {
    name: 'International',
    code: 'INT',
    languages: [{ name: 'English', code: 'en' }],
  },
];

const flatten = <T>(arr: T[][]): T[] => [].concat.apply([], arr);
export const applicableLanguageCodes: string[] = flatten(
  languages.map((country: Language) =>
    country.languages.map((languagesForCountry: CountryLanguage) => languagesForCountry.code)
  )
);
