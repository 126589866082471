import { H2, Ingress } from '../../../common/type';
import styled from 'styled-components';

export const PaddedH2 = styled(H2)`
  margin-top: 3rem;
`;
export const PaddedIngress = styled(Ingress)`
  margin-bottom: 2rem;
`;
export const NoPadH2 = styled(H2)`
  padding: 0;
  margin: 0;
`;
export const NoPadIngress = styled(Ingress)`
  padding: 0;
  margin: 0;
`;
