export interface ExportDataState {
  /**
   * The current status of the light data process
   */
  status?: ExportDataStatus;

  /**
   * The id of the successful request.
   */
  requestId?: string;

  pnrFormValues?: SingleBookingReferenceFormValues;
  profile?: UserProfile;
}

export enum ExportDataStatus {
  INITIAL = 'INITIAL',
  PROCESSING = 'PROCESSING',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

export type PnrFormType = 'finnairPnr' | 'aurinkomatkatBooking';
export type LightExportFormType = PnrFormType | 'finnairPlus';
export type SingleBookingReferenceFormValues = {
  type: PnrFormType;
  firstName: string;
  lastName: string;
  bookingRef: string;
};

export type ExtendedDataRequestFormValues = {
  type: PnrFormType;
};

export type UserProfileResponse = {
  profile: UserProfile;
};

export type UserProfile = {
  memberNumber: string;
  firstname: string;
  lastname: string;
  lang: string;
  mobilePhone: string;
  email: string;
  addresses: Array<UserAddress>;
  tier: string;
};

export enum FinnairAccountType {
  FPLUS = 'fplus',
  FACCOUNT = 'faccount',
}

export type UserAddress = {
  primary: boolean;
  streetAddress: string;
  city: string;
  postalCode: string;
  stateOrProvince: string;
  country: string;
  companyName: string;
};

export type PresignedUrlResponse = {
  preSignedUrl: string;
};

export type GdprForm = {
  address: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  registerType: 'am' | 'ay';
  checkedQuestions?: string[];
  otherContactDetails?: string;
  otherFirstName?: string;
  otherLastName?: string;
  otherTravelDetails?: string;
  pnrs?: string[];
  questionFeedbackDetails?: string;
  questionPurchaseDetails?: string;
  questionPurchaseName?: string;
  questionSurveyDetails?: string;
  requestToRemoveData?: boolean;
  finnairPlusId?: string;
  file?: Array<File>;
  urlToPassport?: string;
};
