import styled, { StyledFunction } from 'styled-components';
import { color, spacing, font } from '../../../assets/styles';

type TitleProps = {
  titleColor: string;
};

export const Title = styled.div<TitleProps>`
  display: block;
  margin: 0 0 ${spacing.xSmall};
  padding: 0;
  text-align: left;
  color: ${({ titleColor }: TitleProps) => titleColor};
  font-family: FinnairSans;
  font-size: ${font.regularPlus};
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
`;

export const TitleLabel = styled.div`
  display: block;
  margin: 0 0 ${spacing.xSmall};
  padding: 0;
  text-align: left;
  color: ${color.charcoal};
  font-family: FinnairSans;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
`;

export const AddressLabel = styled.span`
  text-align: left;
  color: ${color.charcoal};
  font-family: FinnairSans;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
`;

export const AddressValue = styled.span`
  text-align: left;
  color: ${color.charcoal};
  font-family: FinnairSans;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
`;

export const FormSectionTitle = styled.span`
  text-align: left;
  color: ${color.charcoal};
  font-family: FinnairSans;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
`;
