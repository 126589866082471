import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { CONFIG } from '../../app/config';
import { RootState } from '../../app/state.interface';
import { Action } from '../../common/reducer-utils';
import { safeGet } from '../../utils/safe-get';
import { verifyTruthy } from '../../utils/verify';
import { LoginActions } from '../Login/LoginActions';
import {
  PnrFormType,
  SingleBookingReferenceFormValues,
  UserProfileResponse,
  UserProfile,
  PresignedUrlResponse,
  GdprForm,
} from './exportData.interface';
import RequestResponse = Definitions.RequestResponse;

const ROBOT_SUPPORTED_LOCALES = ['en', 'fi_FI'];
const DEFAULT_LOCALE = 'en';
const toRobotSupportedLocale = (locale: string) => {
  if (ROBOT_SUPPORTED_LOCALES.indexOf(locale) !== -1) {
    return locale;
  }
  return DEFAULT_LOCALE;
};

export const FAILED_LIGHT_DATA_REQUEST = '[ExportData] Failed / light data request';
export const FAILED_PROFILE_REQUEST = '[ExportData] Failed / profile request';
export const START_LIGHT_DATA_REQUEST = '[ExportData] Start / light data request';
export const START_PROFILE_REQUEST = '[ExportData] Start / profile request';
export const SUCCESS_LIGHT_DATA_REQUEST = '[ExportData] Success / light data request';
export const SUCCESS_PROFILE_REQUEST = '[ExportData] Success / profile request';
export const SET_BOOKING_FORM_VALUES = '[ExportData] Set booking form values';
export const CLEAR_BOOKING_FORM_VALUES = '[ExportData] Clear booking form values';
export const RESET_EXPORT_DATA = '[ExportData] Reset';
export type FailedLightAccessRequestAction = Action<typeof FAILED_LIGHT_DATA_REQUEST, void>;
export type FailedProfileRequestAction = Action<typeof FAILED_PROFILE_REQUEST, void>;
export type StartLightAccessRequestAction = Action<typeof START_LIGHT_DATA_REQUEST, void>;
export type StartProfileRequestAction = Action<typeof START_PROFILE_REQUEST, void>;
export type SuccessProfileRequestAction = Action<typeof SUCCESS_PROFILE_REQUEST, UserProfile>;
export type SuccessLightAccessRequestAction = Action<typeof SUCCESS_LIGHT_DATA_REQUEST, string>;
export type SetBookingFormValuesAction = Action<typeof SET_BOOKING_FORM_VALUES, SingleBookingReferenceFormValues>;
export type ClearBookingFormValuesAction = Action<typeof CLEAR_BOOKING_FORM_VALUES, void>;
export type ResetExportDataAction = Action<typeof RESET_EXPORT_DATA, void>;

export type DataAccessRequestActionTypes =
  | typeof SUCCESS_PROFILE_REQUEST
  | typeof START_LIGHT_DATA_REQUEST
  | typeof FAILED_LIGHT_DATA_REQUEST
  | typeof SUCCESS_LIGHT_DATA_REQUEST
  | typeof SET_BOOKING_FORM_VALUES
  | typeof CLEAR_BOOKING_FORM_VALUES
  | typeof RESET_EXPORT_DATA;

export const startProfileRequest = (): StartProfileRequestAction => ({
  type: START_PROFILE_REQUEST,
  payload: undefined,
});

export const profileRequestFailed = (): FailedProfileRequestAction => ({
  type: FAILED_PROFILE_REQUEST,
  payload: undefined,
});

export const profileRequestSuccess = (profile: UserProfile): SuccessProfileRequestAction => ({
  type: SUCCESS_PROFILE_REQUEST,
  payload: profile,
});

export const startRobotRequest = (): StartLightAccessRequestAction => ({
  type: START_LIGHT_DATA_REQUEST,
  payload: undefined,
});

export const robotRequestFailed = (): FailedLightAccessRequestAction => ({
  type: FAILED_LIGHT_DATA_REQUEST,
  payload: undefined,
});

export const robotRequestSuccess = (requestIdentifier: string): SuccessLightAccessRequestAction => ({
  type: SUCCESS_LIGHT_DATA_REQUEST,
  payload: requestIdentifier,
});

export const setBookingFormValues = (payload: SingleBookingReferenceFormValues): SetBookingFormValuesAction => ({
  type: SET_BOOKING_FORM_VALUES,
  payload,
});

export const clearBookingFormValues = (): ClearBookingFormValuesAction => ({
  type: CLEAR_BOOKING_FORM_VALUES,
  payload: undefined,
});

export const resetExportDataAccess = (): ResetExportDataAction => ({
  type: RESET_EXPORT_DATA,
  payload: undefined,
});

const extractAccessToken = (state: RootState): string => {
  const accessToken = safeGet(state, 'login', 'token');
  return verifyTruthy(accessToken, 'Light access failed: missing accessToken');
};

/**
 * Send the Light Data Access request to the Data Robot API for the logged in user
 * @param {string} accessToken the logged-in user's access token
 * @param {string} email the email where the confirmation will be sent after request was completed
 * @param {string} locale the locale, must be in {@link ROBOT_SUPPORTED_LOCALES}
 * @return {Promise<string>}
 */
const doLoggedInFinnairPlusRequest = async (accessToken: string, email: string, locale: string): Promise<string> => {
  const url = `${CONFIG.robotApiRoot}/requests/fp`;
  const robotResponse = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: accessToken,
    },
    body: JSON.stringify({ email, locale: toRobotSupportedLocale(locale) }),
  });
  if (robotResponse.status !== 200) {
    throw new Error(`Light access failed: non-200 response from API: [${robotResponse.status}]`);
  }
  const responseJson: RequestResponse = await robotResponse.json();
  return verifyTruthy(responseJson.uuid, 'Light access failed: UUID not present in response');
};

const formTypeToRequestUrl: { [k in PnrFormType]: string } = {
  aurinkomatkatBooking: `${CONFIG.robotApiRoot}/requests/am`,
  finnairPnr: `${CONFIG.robotApiRoot}/requests/ay`,
};

function mapToRequestBody(values: SingleBookingReferenceFormValues, email: string, locale: string) {
  const { firstName, lastName, bookingRef, type } = values;
  const base = {
    firstName,
    lastName,
    email,
    locale: toRobotSupportedLocale(locale),
  };
  if (type === 'finnairPnr') {
    return {
      ...base,
      pnrs: [bookingRef],
    };
  }
  return {
    ...base,
    amBookingIds: [bookingRef],
  };
}

/**
 * Send the GdprRequest to the Data Robot API
 * @param {SingleBookingReferenceFormValues} values the form data to send
 * @param {string} email the email where the confirmation will be sent after request was completed
 * @param {string} locale the locale, must be in {@link ROBOT_SUPPORTED_LOCALES}
 * @return {Promise<string>}
 */
const doCreateGdprRequest = async (form: GdprForm, token: string): Promise<string> => {
  const url = token ? `${CONFIG.robotApiRoot}/loyalty-gdpr-requests` : `${CONFIG.robotApiRoot}/gdpr-requests`;
  const requestBody = form;
  const robotResponse = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(requestBody),
  });
  if (robotResponse.status !== 200) {
    throw new Error(`GdprRequest create failed: non-200 response from API: [${robotResponse.status}]`);
  }
  const responseJson: RequestResponse = await robotResponse.json();
  return verifyTruthy(responseJson.uuid, 'GdprRequest creation failed: UUID not present in response');
};

const uploadPassport = async (file: File, signedRequest: string) => {
  var promiseObj = new Promise(function (resolve: Function, reject: Function) {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', signedRequest, true);
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(true);
        } else {
          reject(xhr.status);
        }
      }
    };
    xhr.send(file);
  });
  return promiseObj;
};

export const getPresignedUrl = async (file: File): Promise<string> => {
  const url = `${CONFIG.robotApiRoot}/generate-attachment-url`;
  const fileName = file.name;
  const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
  const robotResponse = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ fileExtension }),
  });
  const responseJson: PresignedUrlResponse = await robotResponse.json();
  return responseJson.preSignedUrl;
};

// Upload the specified file
const uploadFile = async (files: Array<File>): Promise<string> => {
  let passportFile: File;
  if (files && files.length > 0) {
    passportFile = files[0];
    const presignedUrl: string = await getPresignedUrl(passportFile);
    await uploadPassport(passportFile, presignedUrl);
    return presignedUrl.substring(0, presignedUrl.indexOf('?'));
  }
  return '';
};

/**
 * @see {@link doLightDataRequest}
 */
export const triggerCreateGdprRequest =
  (form: GdprForm, token: string): ThunkAction<void, RootState, void, Action<any>> =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(startRobotRequest());
      // Get presigned url for file upload
      const presignedUrl: string = await uploadFile(form.file || []);
      if (presignedUrl) {
        form.urlToPassport = presignedUrl;
        // @ts-ignore
        delete form.file;
      }
      const uuid = await doCreateGdprRequest(form, token);
      dispatch(robotRequestSuccess(uuid));
    } catch (err) {
      // tslint:disable-next-line:no-console
      dispatch(robotRequestFailed());
    }
  };

const doFetchProfileRequest = async (token: string): Promise<UserProfile> => {
  const url = CONFIG.loyaltyServices.legacy.apiProfileUrl;
  const requestBody = { profileRequest: { cache: 'REFRESH', type: 'FULL' } };
  const robotResponse = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      'x-api-key': CONFIG.loyaltyServices.legacy.apiProfileApiKey,
      oauth_token: token,
    },
    body: JSON.stringify(requestBody),
  });
  if (robotResponse.status !== 200) {
    throw new Error(`GdprRequest create failed: non-200 response from API: [${robotResponse.status}]`);
  }
  const responseJson: UserProfileResponse = await robotResponse.json();
  return responseJson.profile;
};

/**
 *
 */
export const fetchUserProfile =
  (token: string): ThunkAction<void, RootState, void, Action<any>> =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(startProfileRequest());
      const profile = await doFetchProfileRequest(token);
      dispatch(profileRequestSuccess(profile));
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error(err);
      dispatch(profileRequestFailed());
      // force logout if profile fetch failed
      dispatch(LoginActions.logout(window));
    }
  };

/**
 * Send the Light Data Access request to the Data Robot API for PNR users
 * @param {SingleBookingReferenceFormValues} values the form data to send
 * @param {string} email the email where the confirmation will be sent after request was completed
 * @param {string} locale the locale, must be in {@link ROBOT_SUPPORTED_LOCALES}
 * @return {Promise<string>}
 */
const doLightDataRequest = async (
  values: SingleBookingReferenceFormValues,
  email: string,
  locale: string
): Promise<string> => {
  const url = formTypeToRequestUrl[values.type];
  const requestBody = mapToRequestBody(values, email, locale);
  const robotResponse = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  });
  if (robotResponse.status !== 200) {
    throw new Error(`Light access failed: non-200 response from API: [${robotResponse.status}]`);
  }
  const responseJson: RequestResponse = await robotResponse.json();
  return verifyTruthy(responseJson.uuid, 'Light access failed: UUID not present in response');
};

/**
 * @see {@link doLoggedInFinnairPlusRequest}
 */
export const triggerFinnairPlusLightAccessRequest =
  (email: string, locale: string): ThunkAction<void, RootState, void, Action<any>> =>
  async (dispatch: Dispatch<any>, getState: () => RootState) => {
    try {
      dispatch(startRobotRequest());
      const accessToken = extractAccessToken(getState());
      const uuid = await doLoggedInFinnairPlusRequest(accessToken, email, locale);
      dispatch(robotRequestSuccess(uuid));
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error(err);
      dispatch(robotRequestFailed());
    }
  };

/**
 * @see {@link doLightDataRequest}
 */
export const triggerBookingReferenceLightAccessRequest =
  (
    email: string,
    locale: string,
    formValues: SingleBookingReferenceFormValues
  ): ThunkAction<void, RootState, void, Action<any>> =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(startRobotRequest());
      const uuid = await doLightDataRequest(formValues, email, locale);
      dispatch(robotRequestSuccess(uuid));
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error(err);
      dispatch(robotRequestFailed());
    }
  };

export type TriggerFinnairPlusRequest = typeof triggerFinnairPlusLightAccessRequest;
export type TriggerBookingRequest = typeof triggerBookingReferenceLightAccessRequest;
export type ResetExportDataAccess = typeof resetExportDataAccess;
export type TriggerCreateGdprRequest = typeof triggerCreateGdprRequest;
export type FetchUserProfile = typeof fetchUserProfile;
