export interface Config {
  authClientId: string;
  authHost: string;
  consentApiRoot: string;
  consentApiRootV2: string;
  robotApiRoot: string;
  loyaltyServices: {
    legacy: {
      apiProfileUrl: string;
      apiProfileApiKey: string;
    };
  };
  sentryDsn: string;
  stage: string;
}

declare global {
  interface Window {
    CONFIG: Config;
  }
}

/**
 * The config is exposed as an external from /config.js
 */
export const CONFIG: Config = window.CONFIG;
