import * as React from 'react';
import { Field } from 'redux-form';
import { ReduxFormExpand } from '../../../../common/Expand/ReduxFormExpand';
import { titleArea, openIcon, closedIcon } from './expandParts';
import { FormInputSection, TextContainer } from '../Containers';
import { i18n } from '../../../../app/i18n';

export type EraseProps = {
  requestType: string;
  loggedIn: boolean;
};

const renderEraseFields = () => (
  <FormInputSection>
    <TextContainer>{i18n('exportDataScreen.eraseParagraph')}</TextContainer>
  </FormInputSection>
);

const EraseExpand: React.StatelessComponent<EraseProps> = (props: EraseProps) => {
  let eraseTitle = '';
  if (!props.loggedIn) {
    eraseTitle = i18n('exportDataScreen.eraseTitleNotLoggedIn');
  } else if (props.loggedIn && props.requestType === 'aurinkomatkatBooking') {
    eraseTitle = i18n('exportDataScreen.eraseTitleAurinkomatkat');
  } else if (props.loggedIn && props.requestType === 'finnairPrn') {
    eraseTitle = i18n('exportDataScreen.eraseTitleFinnair');
  }

  return (
    <ReduxFormExpand
      openIcon={openIcon()}
      closedIcon={closedIcon()}
      title={titleArea(eraseTitle)}
      content={renderEraseFields()}
      {...props}
    />
  );
};

export const EraseFields = (props: EraseProps) => {
  const { requestType } = props;
  return (
    <Field
      name="requestToRemoveData"
      component={EraseExpand}
      {...{
        requestType,
      }}
    />
  );
};
