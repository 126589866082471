import * as React from 'react';
import { MouseEvent, PureComponent } from 'react';
import { i18n } from '../../../app/i18n';
import { PrimaryButton } from '../../../common/buttons';
import { Centered } from '../../../common/layout';
import { H3 } from '../../../common/type';
import { PaddedIngress } from '../_view';
import styled from 'styled-components';
import { color, font } from '../../../assets/styles';
import { Icon } from '../../../common/Icon';

export interface SuccessScreenProps {
  requestId: string;
  resetState: Function;
  loggedIn: boolean;
}

const Title = styled.div`
  margin-top: 30px;
  font-family: FinnairSans;
  font-size: 28px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: ${color.blue};
`;

const WithPadding = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

export class LightProcessSuccessScreen extends PureComponent<SuccessScreenProps> {
  render() {
    const loggedIn = this.props.loggedIn;
    const ingessText = loggedIn
      ? i18n('exportDataSuccessful.ingressLoggedIn')
      : i18n('exportDataSuccessful.ingressWithoutLoggedIn');
    return (
      <Centered>
        <WithPadding>
          <Icon name={'checkMarkBlock'} size={'xxLarge'} />
          <Title>{i18n('exportDataSuccessful.title')}</Title>
          <PaddedIngress>{ingessText}</PaddedIngress>
          <PrimaryButton onClick={this._handleClick}>{i18n('exportDataSuccessful.callToAction')}</PrimaryButton>
        </WithPadding>
      </Centered>
    );
  }

  _handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.resetState();
  };
}
