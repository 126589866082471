import * as React from 'react';
import styled from 'styled-components';
import { PureComponent } from 'react';
import LoginButton from './LoginButton';
import { spacing } from '../../assets/styles';
import { PrimaryButton } from '../../common/buttons';
import { TextContainer } from '../../common/layout';

export type LoginPromptProps = {
  requestType?: string;
  tipText?: string;
  loggedIn: boolean;
  buttonText: string;
  loggedInBttonText: string;
  navigateTo: () => void;
  redirUri: string;
};

const ButtonWrapper = styled.span`
  display: inline-block;
  padding-top: ${spacing.xxSmall};
`;

export class SimpleLoginPrompt extends PureComponent<LoginPromptProps> {
  render() {
    const { requestType, tipText, loggedIn, redirUri, buttonText, loggedInBttonText } = this.props;
    return (
      <ButtonWrapper>
        {requestType === 'finnairPnr' && tipText && !loggedIn ? <TextContainer> {tipText} </TextContainer> : ''}

        {loggedIn ? (
          <PrimaryButton onClick={this.props.navigateTo}>{loggedInBttonText}</PrimaryButton>
        ) : (
          <TextContainer>
            <LoginButton text={buttonText} redirUri={redirUri} />
          </TextContainer>
        )}
      </ButtonWrapper>
    );
  }
}
