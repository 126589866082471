import * as React from 'react';
import { Field } from 'redux-form';
import { ReduxFormExpand } from '../../../../common/Expand/ReduxFormExpand';
import { titleArea, openIcon, closedIcon } from './expandParts';
import { FormInputSection } from '../Containers';
import { TextInput, DisabledTextInput } from '../../../../common/reduxForms';
import * as validation from '../validations';
import { Note } from '../../../../common/Note';
import { i18n } from '../../../../app/i18n';

export type FeedbackProps = {
  requestType: string;
  loggedIn: boolean;
};

const renderFinnairPlusFormFields = (loggedIn: boolean, requestType: string) => {
  return (
    <FormInputSection>
      <Field
        name="finnairPlusId"
        component={loggedIn ? DisabledTextInput : TextInput}
        label={i18n('exportDataScreen.fplusField')}
        validate={[validation.required, validation.validateFinnairPlusNumber]}
      />
      {requestType !== 'aurinkomatkatBooking' && (
        <Note title={i18n('exportDataScreen.note')} text={i18n('exportDataScreen.fplusHint')} />
      )}
    </FormInputSection>
  );
};

const FinnairPlusExpand: React.StatelessComponent<FeedbackProps> = (props: FeedbackProps) => {
  return (
    <ReduxFormExpand
      openIcon={openIcon()}
      closedIcon={closedIcon()}
      title={titleArea(i18n('exportDataScreen.fplusTitle'))}
      content={renderFinnairPlusFormFields(props.loggedIn, props.requestType)}
      {...props}
    />
  );
};

export const FinnairPlusFields = (props: FeedbackProps) => {
  const { loggedIn, requestType } = props;
  return (
    <Field
      name="QUESTION_FINNAIR_PLUS"
      component={FinnairPlusExpand}
      {...{
        loggedIn,
        requestType,
      }}
    />
  );
};
