import styled from 'styled-components';
//import { color } from '../../../assets/styles';

export const DownloadContainer = styled.div`
  padding-top: 17px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const WithWidth = styled.div`
  width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SubmitButtonWrapper = styled.div`
  margin-top: 17px;
  margin-bottom: 17px;
`;
