import * as React from 'react';
import { PureComponent } from 'react';
import { i18n } from '../../app/i18n';
import { Icon } from '../../common/Icon';
import { ContentMenu, ContentMenuItem } from '../ContentMenu';

export default class NavigationList extends PureComponent {
  render() {
    return (
      <ContentMenu>
        <ContentMenuItem
          href={'/cookie-consent'}
          title={i18n('navigationMenu.cookieConsents.title')}
          description={i18n('navigationMenu.cookieConsents.description')}
          icon={<Icon name="cookieConsentIcon" />}
        />
        <ContentMenuItem
          href={'/marketing-consent'}
          title={i18n('navigationMenu.marketingConsents.title')}
          description={i18n('navigationMenu.marketingConsents.description')}
          icon={<Icon name="marketingConsentIcon" />}
        />
        <ContentMenuItem
          href={'/export-data'}
          title={i18n('navigationMenu.exportData.title')}
          description={i18n('navigationMenu.exportData.description')}
          icon={<Icon name="exportDataIcon" />}
        />
      </ContentMenu>
    );
  }
}
