import styled from 'styled-components';
import { color, spacing, font, weight } from '../../../assets/styles';

const itemSize = '0.8em';
const smallItemSize = '0.2em';
const barWidth = '1em';

export const ConsentIntro = styled.div`
  font-size: ${font.smallPlus};

  a {
    color: ${color.blue};
  }
`;

export const Content = styled.div`
  margin: ${spacing.small};
`;

// Styles copied from slider in finnair-com-ux project
export const Fieldset = styled.fieldset`
  border: 0;
  padding: 0;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  font-size: ${font.smallPlus};

  legend {
    font-weight: ${weight.bold};
    padding-bottom: ${spacing.smallPlus};
    padding-top: ${spacing.small};
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  label {
    display: flex;
    flex-flow: row nowrap;
    flex: 0 0 auto;
    transition: 0.3s all ease;
    cursor: pointer;
    user-select: none;

    > span.content {
      color: ${color.black};
      flex: 1 0 0;
      padding: 0 0 1em 1em;
      transition: color 0.3s ease;

      > span {
        font-size: ${font.small};
      }
    }

    > span.bar {
      box-sizing: border-box;
      display: flex;
      flex: 0 0 ${barWidth};
      align-items: center;
      flex-flow: column nowrap;
      background: ${color.lightGray};
      overflow: hidden;
    }

    &:first-of-type > span.bar {
      border-top-left-radius: calc(${barWidth} / 2);
      border-top-right-radius: calc(${barWidth} / 2);
    }
    &:last-of-type > span.bar {
      border-bottom-left-radius: calc(${barWidth} / 2);
      border-bottom-right-radius: calc(${barWidth} / 2);
      align-self: flex-start;
      justify-content: flex-end;
      height: ${barWidth};
    }
  }

  input + label {
    > span.bar {
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        transform-origin: top center;
        overflow: hidden;
        background: ${color.blue};
      }

      &:after {
        top: calc(${smallItemSize} * 2);
        width: ${smallItemSize};
        height: ${smallItemSize};
        border-radius: ${smallItemSize};
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background: ${color.white};
      }
    }
  }

  input:checked + label {
    > span.bar {
      &:before {
        height: ${barWidth};
        border-bottom-right-radius: 100%;
        border-bottom-left-radius: 100%;
        background: ${color.blue};
      }

      &:after {
        top: calc((1em - ${itemSize}) / 2);
        width: ${itemSize};
        height: ${itemSize};
        border-radius: ${itemSize};
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background: ${color.white};
      }
    }
  }

  input:checked ~ input:not(:checked) {
    & + label,
    & ~ input + label {
      > span.content {
        color: rgba(0, 0, 0, 0.5);
      }
      > span.bar {
        &:before {
          background: ${color.lightGray};
        }
      }
    }
  }
`;
