import styled from 'styled-components';
import { breakPoint, color, spacing, font, value } from '../assets/styles';

export const PageTitle = styled.h1`
  color: ${color.blue};
  margin: 0;
  @media (min-width: ${breakPoint.desktopMin}) {
    font-size: ${font.title};
  }
  @media (max-width: ${breakPoint.mobileMax}) {
    font-size: ${font.large};
  }
`;

export const Ingress = styled.div`
  color: ${color.charcoal};
  margin: 0;
  padding: 0;
  padding-top: ${spacing.small};
  font-family: FinnairSans;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${color.charcoal};

  @media (max-width: ${breakPoint.mobileMax}) {
    display: none;
  }
`;

export const MobileIngress = styled.div`
  color: ${color.charcoal};
  font-size: ${font.smallPlus};
  padding: ${spacing.medium} ${spacing.medium} ${spacing.small} ${spacing.medium};
  max-width: ${value.pageContentWidth};
  margin: 0 auto ${spacing.small} auto;
  background-color: ${color.white};

  @media (min-width: ${breakPoint.desktopMin}) {
    display: none;
  }
`;

export const H2 = styled.h2`
  font-size: ${font.regularPlus};
  margin-top: ${spacing.small};
  margin-bottom: ${spacing.xSmall};
  font-weight: bold;
  color: ${color.black};
`;

export const H3 = styled.h3`
  font-size: ${font.smallPlus};
  line-height: 1.25;
  font-weight: bold;
  color: ${color.charcoal};
`;

export const BodyText = styled.p`
  font-size: ${font.smallPlus};
  line-height: 1.125rem;
  color: ${color.charcoal};
`;
