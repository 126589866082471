import styled from 'styled-components';
import { value, color } from '../../assets/styles';

export const HomeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Divider = styled.div`
  width: 192px;
  height: 4px;
  background-color: ${color.blue};
`;

export const ContentContainer = styled.div`
  max-width: ${value.pageContentWidth};
  padding-bottom: 30px;
`;

export const PrivacyTextContainer = styled.div`
  margin-top: 30px;
`;

export const NavigationListContainer = styled.div`
  margin-top: 60px;
`;
