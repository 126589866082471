import * as Sentry from '@sentry/react';

export const initSentry = () => {
  if (!window.CONFIG.sentryDsn) {
    return;
  }

  Sentry.init({
    dsn: window.CONFIG.sentryDsn,
    environment: window.CONFIG.stage,
    allowUrls: [
      // To get sentry working locally
      /webpack-internal/,
      // MATCH Content-Security-Policy here
      /\.finnair\.com/,
      /\.googletagmanager\.com/,
      /\.google-analytics\.com/,
    ],
    normalizeDepth: 4,
  });
};
