import * as React from 'react';
import { PureComponent } from 'react';
import styled from 'styled-components';
import { i18n } from '../../../app/i18n';
import { breakPoint, color, spacing, value, weight } from '../../../assets/styles';
import { CenteredForm } from '../../../common/forms';
import { Icon } from '../../../common/Icon';
import { ExtendedDataRequestFormValues, PnrFormType, UserProfile } from '../exportData.interface';
import { CompanySelection } from '../CompanySelection/CompanySelection';
import { uiEvent } from '../../../dataLayer';
import { BoxWithHeading } from '../../../common/Box';

export const IconsFlexContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  box-sizing: border-box;
  border-radius: ${value.rounding};
  background-color: ${color.white};
  transition: border-color 0.3s ease;
  &:focus-within {
    border-color: ${color.ice};
  }
  margin: ${spacing.large} 0;

  @media (max-width: ${breakPoint.mobileMax}) {
    display: block;
    width: 100%;
    margin-bottom: ${spacing.medium};
  }

  > div {
    @media (max-width: ${breakPoint.mobileMax}) {
      margin-bottom: ${spacing.medium};
    }
    @media (min-width: ${breakPoint.desktopMin}) {
      width: 33%;
    }
  }
`;

export const IconItem = styled.div``;

export const IconLabel = styled.div`
  margin: auto;
  margin-top: 0.5rem;
  font-size: 14px;
  width: 85%;
`;

export const MiddleParagraph = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: ${color.black};
  margin-bottom: 2rem;
  @media (max-width: ${breakPoint.mobileMax}) {
    width: 100%;
  }
`;

export const InstructionsDiv = styled.div`
  @media (max-width: ${breakPoint.mobileMax}) {
    width: 100%;
  }
`;

export const InstructionsTitle = styled.span`
  font-size: 1rem;
  font-weight: ${weight.bold};
  text-align: center;
  color: ${color.black};
  text-transform: uppercase;
`;

const OrderedInstructionSteps = styled.div`
  margin-bottom: ${spacing.large};
  font-size: 14px;
`;

const OrderedList = styled.ol`
  line-height: 1.25;
  padding-left: ${spacing.small};
  text-align: left;

  li {
    padding-left: ${spacing.small};
  }
`;

export interface ExtendedDataRequestFormProps {
  locale: string;
  history: any;
  loggedIn: boolean;
  handleSubmit?: (pnrFormValues: ExtendedDataRequestFormValues) => void;
  profile: UserProfile;
}

export interface ExtendedDataRequestFormState {
  selectedType: PnrFormType;
}

export class ExtendedDataRequestForm extends PureComponent<ExtendedDataRequestFormProps, ExtendedDataRequestFormState> {
  state = {
    selectedType: CompanySelection.DEFAULT_VALUE,
  };

  _navigateToLoggedInForm = () => this.props.history.push(`/export-data/${this.state.selectedType}`);

  _changeCompany = (selectedType: PnrFormType) => {
    uiEvent('extended-data-request', 'click', `extended-data-request-service_${selectedType}`, 'radio');
    this.setState({ selectedType });
  };

  render() {
    return (
      <BoxWithHeading title={i18n('extendedDataRequestForm.title')}>
        <CenteredForm>
          <IconsFlexContainer>
            <IconItem>
              <Icon name={'checkList'} size={'xxLarge'} />
              <IconLabel>{i18n('extendedDataRequestForm.iconsLabel.checkList')}</IconLabel>
            </IconItem>
            <IconItem>
              <Icon name={'holdMyBooking'} size={'xxLarge'} />
              <IconLabel>{i18n('extendedDataRequestForm.iconsLabel.holdMyBooking')}</IconLabel>
            </IconItem>
            <IconItem>
              <Icon name={'onlineChecked'} size={'xxLarge'} />
              <IconLabel>{i18n('extendedDataRequestForm.iconsLabel.onlineChecked')}</IconLabel>
            </IconItem>
          </IconsFlexContainer>
          <MiddleParagraph
            dangerouslySetInnerHTML={{
              __html: i18n('extendedDataRequestForm.paragraphText'),
            }}
          />
          <CompanySelection
            loggedIn={this.props.loggedIn}
            onChange={this._changeCompany}
            source="extended-data-request"
            navigateTo={this._navigateToLoggedInForm}
          />
        </CenteredForm>
      </BoxWithHeading>
    );
  }
}
