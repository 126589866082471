export interface Action<ACTION_TYPE, PAYLOAD_TYPE = any> {
  type: ACTION_TYPE;
  payload: PAYLOAD_TYPE;
}

interface ActionReducer<STATE, ACTION_TYPE extends string> {
  (state: STATE, action: Action<ACTION_TYPE, any>): STATE;
}

interface ActionHandler<STATE, PAYLOAD, ACTION_TYPE> {
  (state: STATE, action: Action<ACTION_TYPE, PAYLOAD>): STATE;
}

type ReducerMap<STATE, ACTION_TYPE extends string> = { [T in ACTION_TYPE]: ActionHandler<STATE, any, T> };

export const createReducer = <STATE, ACTION_TYPE extends string>(
  initialState: STATE,
  reducerMap: ReducerMap<STATE, ACTION_TYPE>
): ActionReducer<STATE, ACTION_TYPE> => {
  return <T>(state: STATE = initialState, action: Action<ACTION_TYPE, T>) => {
    const actionHandler: ActionHandler<STATE, T, ACTION_TYPE> = reducerMap[action.type];
    if (!!actionHandler) {
      return actionHandler(state, action);
    }
    return state;
  };
};
