import * as React from 'react';
import { Field } from 'redux-form';
import { ReduxFormExpand } from '../../../../common/Expand/ReduxFormExpand';
import { titleArea, openIcon, closedIcon } from './expandParts';
import { FormInputSection } from '../Containers';
import { TextInput, DisabledTextInput } from '../../../../common/reduxForms';
import * as validation from '../validations';
import { i18n } from '../../../../app/i18n';

export type OtherTripsProps = {
  loggedIn: boolean;
};

const renderMyPurchasesFields = (loggedIn: boolean) => (
  <FormInputSection>
    <Field
      name="finnairPlusId"
      component={loggedIn ? DisabledTextInput : TextInput}
      label={i18n('exportDataScreen.purchasesFplusId')}
      validate={[validation.validateFinnairPlusNumber]}
    />
    <Field
      name="questionPurchaseName"
      component={TextInput}
      label={i18n('exportDataScreen.purchasesName')}
      validate={[validation.required, validation.notNumbers]}
    />
    <Field
      name="questionPurchaseDetails"
      component={TextInput}
      label={i18n('exportDataScreen.purchasesFlightNumberAndDate')}
      hint={i18n('exportDataScreen.purchasesFlightNumberAndDateHint')}
      validate={[]}
    />
  </FormInputSection>
);

const PurchasesExpand: React.StatelessComponent<OtherTripsProps> = (props: OtherTripsProps) => {
  return (
    <ReduxFormExpand
      openIcon={openIcon()}
      closedIcon={closedIcon()}
      title={titleArea(i18n('exportDataScreen.purchasesTitle'))}
      content={renderMyPurchasesFields(props.loggedIn)}
      {...props}
    />
  );
};

export const PurchasesFields = (props: OtherTripsProps) => {
  const { loggedIn } = props;
  return (
    <Field
      name="QUESTION_PURCHASES"
      component={PurchasesExpand}
      {...{
        loggedIn,
      }}
    />
  );
};
