import { StatelessComponent } from 'react';
import * as React from 'react';
import styled from 'styled-components';
import { color, spacing, value, font } from '../../assets/styles';
import { Centered } from '../layout';

const finnairEmblem = require('../../assets/images/finnair-emblem-white.svg');
const emblemWidth = '3rem';

const Box = styled.div`
  margin: 0 auto;
  max-width: ${value.pageContentWidth};
  overflow: hidden;
`;

const Title = styled.h2`
  border-top-left-radius: ${value.rounding};
  border-top-right-radius: ${value.rounding};
  background-color: ${color.blue};
  color: ${color.white};
  box-sizing: border-box;
  background-image: url('${finnairEmblem}');
  background-repeat: no-repeat;
  background-position: calc(100% - ${spacing.medium}) center;
  background-size: ${emblemWidth} auto;
  padding: ${spacing.small} calc(${emblemWidth} + 2 * ${spacing.medium}) ${spacing.small} ${spacing.medium};
  margin: 0;
  font-size: ${font.regular};
`;
const Content = styled.div`
  border-style: solid;
  border-width: 0 1px 1px;
  border-color: ${color.lightGray};
  box-sizing: border-box;
  padding: ${spacing.small};
  background-color: ${color.white};
  position: relative;
`;

export type Props = {
  title: string;
  children: any;
};

export const BoxWithHeading: StatelessComponent<Props> = ({ title, children }: Props) => (
  <Box>
    <Title>{title}</Title>
    <Content>{children}</Content>
  </Box>
);

export const WhiteBgBox = styled(Centered)`
  background-color: ${color.white};
  border: 1px solid ${color.lightGray};
  overflow: hidden;
  max-width: ${value.pageContentWidth};
  margin: 0 auto ${spacing.medium} auto;
`;

export const TabBox = styled(Centered)`
  background-color: ${color.white};
  border-bottom-left-radius: ${value.rounding};
  border-bottom-right-radius: ${value.rounding};
  border: 1px solid ${color.lightGray};
  border-top: none;
  overflow: hidden;
  max-width: ${value.pageContentWidth};
  margin: 0 auto ${spacing.medium} auto;
`;
