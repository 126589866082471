import * as React from 'react';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { RootState } from '../../app/state.interface';
import { i18n } from '../../app/i18n';
import { MainContainer } from '../../common/layout';
import HeroImage from '../HeroImage';
import { DownloadContainer, WithWidth } from './Containers';
import { Icon } from '../../common/Icon';
import { Title, BoldText, NormalText } from './Texts';
import { SimpleLoginPrompt } from '../Login/SimpleLoginPrompt';

export type Props = RouteComponentProps<void> & {
  loggedIn: boolean;
};

export class LoginToDownload extends Component<Props> {
  render() {
    return (
      <MainContainer>
        <HeroImage title={i18n('loginToDownload.title')} ingress={i18n('loginToDownload.ingress')} />
        <DownloadContainer>
          <WithWidth>
            <Icon name={'exportDataIcon'} size="xxLarge" />
            <Title>{i18n('loginToDownload.subTitle')}</Title>
            <BoldText>{i18n('loginToDownload.boldText')}</BoldText>
            <SimpleLoginPrompt
              loggedIn={this.props.loggedIn}
              buttonText={i18n('loginToDownload.login')}
              loggedInBttonText={i18n('loginToDownload.proceed')}
              navigateTo={this._navigateToLoggedInForm}
              redirUri={`${window.location.href}/download`}
            />
            <NormalText>{i18n('loginToDownload.text')}</NormalText>
          </WithWidth>
        </DownloadContainer>
      </MainContainer>
    );
  }

  private _navigateToLoggedInForm = () => this.props.history.push(`/reports/download`);
}

export default connect(
  (state: RootState) => ({
    loggedIn: !!state.login.token,
  }),
  {}
)(withRouter(LoginToDownload));
