import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../app/state.interface';
import { Flag } from '../../common/flag';
import { Language } from './language.interface';
import { LanguageContentWrapper } from './_view';
import { languages } from './allLanguages';
import { i18n } from '../../app/i18n';

export interface LanguageSwitcherProps {
  showLanguageSwitcher: boolean;
}

export class LanguageSwitcher extends React.Component<LanguageSwitcherProps> {
  renderLanguages() {
    return languages.map((lang: Language) => {
      const countryLang = lang.languages.map((countryLanguage: Language) => {
        return (
          <span className="countryLanguage" key={countryLanguage.name}>
            <a
              href={`${window.location.origin}${window.location.pathname}?locale=${countryLanguage.code}`}
              className="language-link"
            >
              {countryLanguage.name}
            </a>
          </span>
        );
      });
      return (
        <li className="languages-list" key={lang.code} value={lang.code}>
          <Flag flag={lang.code} />
          <span className="countries">{lang.name}</span>
          {countryLang}
        </li>
      );
    });
  }

  render() {
    if (!this.props.showLanguageSwitcher) {
      return null;
    }
    return (
      <LanguageContentWrapper>
        <p className="languageMenuTitle">{i18n('languageMenuTitle')}</p>
        <ul className="languages">{this.renderLanguages()}</ul>
      </LanguageContentWrapper>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    showLanguageSwitcher: state.language.showLanguageSwitcher,
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);
