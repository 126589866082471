import * as React from 'react';
import { i18n } from '../../app/i18n';
import { MainContainer } from '../../common/layout';
import HeroImage from '../HeroImage';
import NavigationList from '../NavigationList/index';
import { HomeContainer, Divider, ContentContainer, PrivacyTextContainer, NavigationListContainer } from './Containers';
import { PrinciplesTitle, Principles, PolicyText, PolicyLink } from './Texts';

const HomeScreen = () => (
  <MainContainer>
    <HeroImage title={i18n('welcome')} ingress={i18n('ingress')} />
    <HomeContainer>
      <Divider />
      <ContentContainer>
        <PrinciplesTitle>{i18n('landingPage.title')}</PrinciplesTitle>
        <Principles>
          <ul>
            <li>{i18n('landingPage.principle1')}</li>
            <li>{i18n('landingPage.principle2')}</li>
            <li>{i18n('landingPage.principle3')}</li>
            <li>{i18n('landingPage.principle4')}</li>
            <li>{i18n('landingPage.principle5')}</li>
            <li>{i18n('landingPage.principle6')}</li>
          </ul>
        </Principles>
        <PrivacyTextContainer>
          <PolicyText>
            <PolicyLink href={i18n('landingPage.privacyPolicyLinkUrl')} target="_blank" rel="noopener">
              {i18n('landingPage.privacyPolicyLink')}
            </PolicyLink>
            {i18n('landingPage.paragraph1')}
          </PolicyText>
          <PolicyText>{i18n('landingPage.paragraph2')}</PolicyText>
          <PolicyText>
            {i18n('landingPage.paragraph3')}
            <PolicyLink href="mailto:privacy@finnair.com">privacy@finnair.com</PolicyLink>
          </PolicyText>
        </PrivacyTextContainer>
      </ContentContainer>
      <Divider />
    </HomeContainer>
    <NavigationListContainer>
      <NavigationList />
    </NavigationListContainer>
  </MainContainer>
);

export default HomeScreen;
