import * as React from 'react';
import { i18n } from '../../app/i18n';
import { Content } from '../CookieConsent/view';

export type SpinnerWhenLoadingProps = {
  loading: boolean;
  children: any;
};

export const SpinnerWhenLoading = ({ loading, children }: SpinnerWhenLoadingProps) => (
  <Content>{loading ? i18n('cookieConsentPage.loadingText') : children}</Content>
);
