import * as React from 'react';
import { Field, FieldArray } from 'redux-form';
import { ReduxFormExpand } from '../../../../common/Expand/ReduxFormExpand';
import { titleArea, openIcon, closedIcon } from './expandParts';
import { FormInputSection } from '../Containers';
import { TextInput } from '../../../../common/reduxForms';
import * as validation from '../validations';
import { AddItemButton } from '../AddItemButton';
import { i18n } from '../../../../app/i18n';

const renderDynamicFields = (props: any) => {
  const { fields } = props;
  return (
    <div>
      {fields.map((member: string, index: number) => {
        const name = `questionSurveyDetails[${index}]`;
        return (
          <Field
            key={index}
            name={name}
            component={TextInput}
            label={i18n('exportDataScreen.surveyDetails')}
            hint={i18n('exportDataScreen.surveyDetailsHint')}
            validate={[validation.required]}
          />
        );
      })}
      <AddItemButton title={i18n('exportDataScreen.surveyAddRow')} onClick={() => fields.push('')} />
    </div>
  );
};

const renderSurveyFields = () => (
  <FormInputSection>
    <FieldArray name="questionSurveyDetails" component={renderDynamicFields} />
  </FormInputSection>
);

const FinnairPlusExpand: React.StatelessComponent = (props) => {
  return (
    <ReduxFormExpand
      openIcon={openIcon()}
      closedIcon={closedIcon()}
      title={titleArea(i18n('exportDataScreen.surveyTitle'))}
      content={renderSurveyFields()}
      {...props}
    />
  );
};

export const SurveyFields = () => <Field name="QUESTION_SURVEY" component={FinnairPlusExpand} />;
