import styled from 'styled-components';
import { value, font, weight, color } from '../../../assets/styles';

export const LanguageContentWrapper = styled.div`
  .languages {
    column-count: 2;
    column-gap: 2rem;
    padding: 0;
    list-style: none;
  }
  .languages-list {
    padding: 0.3rem 0;
    border-bottom: 1px solid ${color.lightGray};
  }
  .countries {
    margin-right: 1rem;
    display: inline-block;
    font-weight: ${weight.bold};
    font-size: ${font.smallPlus} !important;
  }
  .countryLanguage {
    display: inline-block;
    margin-left: ${value.marginLeft};
  }
  .language-link {
    color: ${color.black};
    text-decoration: none;
    font-size: ${font.smallPlus} !important;
  }
`;
