import * as React from 'react';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { CONFIG } from '../../app/config';
import { i18n } from '../../app/i18n';
import { RootState } from '../../app/state.interface';
import { PrimaryLinkButton } from '../../common/buttons';

export interface LoginButtonProps {
  locale: string;
  redirUri?: string;
  text?: string;
}

export const createLoginLink = (locale: string, redirUri: string = window.location.href) => {
  const loginParams = {
    lang: locale,
    response_type: 'token',
    client_id: CONFIG.authClientId,
    redirect_uri: redirUri,
  };
  const paramString = Object.keys(loginParams)
    .map((p: keyof typeof loginParams) => `${encodeURIComponent(p)}=${encodeURIComponent(loginParams[p])}`)
    .join('&');
  return `${CONFIG.authHost}/cas/oauth2.0/authorize?${paramString}`;
};

export class LoginButton extends PureComponent<LoginButtonProps> {
  render() {
    const { locale, redirUri } = this.props;
    const redir = redirUri ? redirUri : window.location.href;
    const loginLink = createLoginLink(locale, redir);
    const loginButtonText = this.props.text ? this.props.text : i18n('login.callToAction');
    return (
      <PrimaryLinkButton as="a" href={loginLink}>
        {loginButtonText}
      </PrimaryLinkButton>
    );
  }
}

export default connect((state: RootState) => ({
  locale: state.language.currentLocale,
}))(LoginButton);
