import * as React from 'react';
import { Route, RouteProps, Switch } from 'react-router';
import { i18n } from '../../app/i18n';
import { FullWidth } from '../../common/layout';
import { BreadCrumbBar } from './view';

type BreadCrumbProps = {
  title: string;
  href: string;
};

const Home = () => <a href="/">{i18n('home')}</a>;
const Other = ({ title, href }: BreadCrumbProps) => (
  <div>
    <Home />
    {' / '}
    <a className="bold" href={href}>
      {title}
    </a>
  </div>
);

export const BreadCrumb = ({ location }: RouteProps) => (
  <FullWidth>
    <BreadCrumbBar>
      <Switch location={location}>
        <Route
          path="/export-data"
          render={() => <Other href={'/export-data'} title={i18n('navigationMenu.exportData.title')} />}
        />
        <Route
          path="/cookie-consent"
          render={() => <Other href={'/cookie-consent'} title={i18n('navigationMenu.cookieConsents.title')} />}
        />
        <Route
          path="/marketing-consent"
          render={() => <Other href={'/marketing-consent'} title={i18n('navigationMenu.marketingConsents.title')} />}
        />
        <Route
          path="/download"
          render={() => <Other href={'/download'} title={i18n('navigationMenu.download.title')} />}
        />
        <Route path="/" component={Home} />
      </Switch>
    </BreadCrumbBar>
  </FullWidth>
);
