import * as React from 'react';
import { Dispatch } from 'redux';
import {
  formValueSelector,
  reduxForm,
  InjectedFormProps,
  getFormSyncErrors,
  hasSubmitFailed,
  FormErrors,
} from 'redux-form';
import { UserProfile } from '../exportData.interface';
import { TextContainer, SubmitContainer, SubmitButtonWrapper } from './Containers';
import { PrimaryButton } from '../../../common/buttons';
import { AurinkomatkatPrimaryButton } from '../../../common/buttons';
import { FinnairPlusFields } from './formParts/FinnairPlusFields';
import { OtherTripsFields } from './formParts/OtherTripsFields';
import { PurchasesFields } from './formParts/PurchasesFields';
import { FeedbackFields } from './formParts/FeedbackFields';
import { SurveyFields } from './formParts/SurveyFields';
import { AdditionalFields } from './formParts/AdditionalFields';
import { EraseFields } from './formParts/EraseFields';
import { BaseFields } from './formParts/BaseFields';
import { i18n } from '../../../app/i18n';
import { connect } from 'react-redux';
import { CustomErrorSpan } from '../../../common/reduxForms';

export interface FormData {
  firstName: string;
  lastName: string;
  finnairPlusId: string;
  phoneNumber: string;
  address: string;
  email: string;
}

export interface OwnProps {
  requestType: string;
  submitClicked: boolean;
  titleColor: string;
  locale: string;
  loggedIn: boolean;
  profile: UserProfile;
}

export interface ReduxStateProps {
  isFileUploadRequired: boolean;
  syncErrors: FormErrors<{ oneSelectionRequired?: boolean }, string>;
  shouldValidate: Function;
}

export interface DispatchProps {
  onSubmit: (data: FormData, dispatch: Dispatch<any>, props: OwnProps) => void;
}

interface ErrorsObject {
  [key: string]: string;
}

const validate = (values: any): ErrorsObject => {
  const errors: ErrorsObject = {};

  if (
    !values.QUESTION_ADDITIONAL &&
    !values.QUESTION_FEEDBACK &&
    !values.QUESTION_FINNAIR_PLUS &&
    !values.QUESTION_OTHER_TRIPS &&
    !values.QUESTION_PURCHASES &&
    !values.QUESTION_SURVEY
  ) {
    errors.oneSelectionRequired = 'At least one selection required';
  }

  return errors;
};

const renderPrivacyLink = (requestType: string, locale: string) => {
  const forMoreInfoText = i18n('exportDataScreen.forMoreInfo');
  const privacyPolicyText = i18n('exportDataScreen.privacyPolicy');
  if (requestType === 'aurinkomatkatBooking') {
    return (
      <TextContainer>
        {forMoreInfoText}{' '}
        <a target="_blank" href="https://www.aurinkomatkat.fi/yritysinfo/tietosuoja">
          {privacyPolicyText}
        </a>
      </TextContainer>
    );
  } else {
    const language = locale === 'en' ? 'gb' : 'fi';
    return (
      <TextContainer>
        {forMoreInfoText}{' '}
        <a target="_blank" href={`https://www.finnair.com/fi/${language}/privacy-policy`}>
          {privacyPolicyText}
        </a>
      </TextContainer>
    );
  }
};

type AllSampleFormProps = OwnProps & DispatchProps & ReduxStateProps & InjectedFormProps<FormData, OwnProps>;

const SampleForm: React.SFC<AllSampleFormProps> = (props) => {
  const requestType = props.requestType;
  const mentionFinnairPlus = !props.loggedIn || (props.loggedIn && props.profile && props.profile.tier !== 'FINNAIRID');
  const isFileUploadRequired = props.isFileUploadRequired;
  const oneSelectionRequired = props.syncErrors?.oneSelectionRequired;
  const shouldValidate = props.shouldValidate;

  return (
    <form onSubmit={props.handleSubmit(props.onSubmit)}>
      {mentionFinnairPlus && <FinnairPlusFields requestType={requestType} loggedIn={props.loggedIn} />}
      <OtherTripsFields requestType={requestType} />
      {mentionFinnairPlus && <PurchasesFields loggedIn={props.loggedIn} />}
      <FeedbackFields requestType={requestType} />
      <SurveyFields />
      <AdditionalFields requestType={requestType} />
      <EraseFields loggedIn={props.loggedIn} requestType={requestType} />

      <span id="oneSelectionRequired">
        {oneSelectionRequired && shouldValidate() && (
          <CustomErrorSpan>{i18n('formValidationMessages.oneSelectionRequired')}</CustomErrorSpan>
        )}
      </span>

      <BaseFields titleColor={props.titleColor} isFileUploadRequired={isFileUploadRequired} />
      <SubmitContainer>
        {renderPrivacyLink(requestType, props.locale)}
        <SubmitButtonWrapper>
          {requestType === 'aurinkomatkatBooking' && (
            <AurinkomatkatPrimaryButton type="submit" disabled={props.submitClicked}>
              {i18n('exportDataScreen.submitButton')}
            </AurinkomatkatPrimaryButton>
          )}
          {requestType !== 'aurinkomatkatBooking' && (
            <PrimaryButton type="submit" disabled={props.submitClicked}>
              {i18n('exportDataScreen.submitButton')}
            </PrimaryButton>
          )}
        </SubmitButtonWrapper>
      </SubmitContainer>
    </form>
  );
};

const formName = 'GdprForm';
const selector = formValueSelector(formName);
const formSyncErrors = getFormSyncErrors(formName);

const ExportFormWithCustomValidation = connect((state) => {
  const isFileUploadRequired = !!selector(state, 'QUESTION_OTHER_TRIPS') || !!selector(state, 'QUESTION_ADDITIONAL');
  const syncErrors = formSyncErrors(state);
  return {
    isFileUploadRequired,
    syncErrors,
    shouldValidate: () => hasSubmitFailed(formName)(state),
  } as ReduxStateProps;
})(SampleForm);

export const ExportForm = reduxForm<FormData, OwnProps>({ validate })(ExportFormWithCustomValidation);
