import * as React from 'react';
import { connect, ConnectedComponent } from 'react-redux';
import { Dispatch } from 'redux';
import { i18n } from '../../app/i18n';
import { RootState } from '../../app/state.interface';
import { SecondaryButton } from '../../common/buttons';
import { LoginActions } from './LoginActions';

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch: any) => ({
  handleLogout: () => dispatch(LoginActions.logout(window)),
});

export const LogoutButton: ConnectedComponent<any, any> = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ handleLogout }: any) => <SecondaryButton onClick={handleLogout}>{i18n('login.logoutAction')}</SecondaryButton>);
