import * as React from 'react';
import { InputHTMLAttributes, LabelHTMLAttributes } from 'react';
import styled from 'styled-components';
import { i18n } from '../../../../app/i18n';
import { color } from '../../../../assets/styles';
import { PnrFormType } from '../../exportData.interface';

export type RadioInputProps = {
  value: PnrFormType;
  selected: boolean;
  onChange?: InputHTMLAttributes<HTMLInputElement>['onChange'];
  source: string;
};

export const RadioContainer = styled.div`
  height: 100%;
`;
export const RadioButton = styled.input`
  display: block;
  position: absolute;
  left: -9999px;
  height: 0;
  width: 0;
  overflow: hidden;
`;

type LabelProps = LabelHTMLAttributes<HTMLLabelElement> & {
  value: PnrFormType;
};

// TODO: Replace with real SVG ones
export const ICONS: { [s in PnrFormType]: () => string } = {
  finnairPnr: () => require('./finnair.png'),
  aurinkomatkatBooking: () => require('./aurinkomatkat.png'),
};

const borderWidth = '1px';
export const RadioLabel = styled.label<LabelProps>`
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  box-sizing: border-box;
  border-bottom: ${borderWidth} solid ${color.lightGray};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url('${({ value }: LabelProps) => ICONS[value]()}');
  background-color: ${color.offGrey};

  ${RadioButton}:checked + & {
    border-bottom: ${borderWidth} solid ${color.white};
    background-color: ${color.white};
  }
  ${RadioButton}:focus + & {
  }
`;

export const RadioInput = ({ onChange, value, selected, source }: RadioInputProps) => {
  const inputId = `${source}-companySelection-${value}`;
  return (
    <RadioContainer>
      <RadioButton
        type="radio"
        name="company-selection"
        value={value}
        id={inputId}
        tabIndex={selected ? 0 : -1}
        onChange={onChange}
        checked={selected}
        aria-checked={selected}
      />
      <RadioLabel htmlFor={inputId} value={value} aria-label={i18n(`exportSingleBookingForm.tabs.${value}`)} />
    </RadioContainer>
  );
};
