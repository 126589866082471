import * as React from 'react';
import { Provider } from 'react-redux';
import { Redirect, Route, RouteProps, Switch } from 'react-router';
import { Router } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';
import { Store } from 'redux';
import { ThemeProvider } from 'styled-components';
import AppWrapper from '../modules/AppWrapper';
import { BreadCrumb } from '../modules/BreadCrumb';
import CookieConsentPage from '../modules/CookieConsent';
import LoginToDownload from '../modules/LoginToDownload';
import ExportDataScreen from '../modules/ExportData/ExportDataScreen';
import ExportFormScreen from '../modules/ExportData/ExportFormScreen';
import DownloadReport from '../modules/DownloadReport';
import Header from '../modules/Header/index';
import HomeScreen from '../modules/HomeScreen';
import LanguageSwitcher from '../modules/LanguageSwitcher/LanguageSwitcher';
import LoginWrapper from '../modules/Login/LoginWrapper';
import MarketingConsentScreen from '../modules/MarketingConsent/MarketingConsentScreen';
import MarketingPreferencesScreen from '../modules/MarketingConsent/MarketingPreferencesScreen';
import { getGlobalUiLocale, setGlobalUiLocaleFromQueryString } from './i18n';
import { RootState } from './state.interface';
import configureStore from './store';
import { theme } from './theme';
import Footer from '../common/Footer/Footer';
import { pageView } from '../dataLayer';
import { createFetchDataFromBackendAction } from '../modules/CookieConsent/CookieConsentActions';

setGlobalUiLocaleFromQueryString(document.location.search);
pageView(document.location.pathname);

const store: Store<RootState> = configureStore(getGlobalUiLocale());
store.dispatch(createFetchDataFromBackendAction());

const history = createHistory();
history.listen((location, _action) => {
  pageView(location.pathname);
});

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <AppWrapper>
            <Header />
            <LanguageSwitcher />
            <Router history={history}>
              <Route
                render={({ location }: RouteProps) => (
                  <LoginWrapper>
                    <BreadCrumb location={location} />
                    <Switch location={location}>
                      <Route exact={true} path="/marketing-consent" component={MarketingConsentScreen} />
                      <Route
                        exact={true}
                        path="/marketing-consent/manage-preferences"
                        component={MarketingPreferencesScreen}
                      />
                      <Route exact={true} path="/cookie-consent" component={CookieConsentPage} />
                      <Route exact={true} path="/export-data" component={ExportDataScreen} />
                      <Route
                        exact={true}
                        path="/export-data/:requestType(finnairPnr|aurinkomatkatBooking|finnairPlus)"
                        component={ExportFormScreen}
                      />
                      <Route exact={true} path="/reports" component={LoginToDownload} />
                      <Route exact={true} path="/reports/download" component={DownloadReport} />
                      <Route exact={true} path="/" component={HomeScreen} />
                      <Route path="/" render={() => <Redirect to="/" />} />
                    </Switch>
                  </LoginWrapper>
                )}
              />
            </Router>
            <Footer />
          </AppWrapper>
        </ThemeProvider>
      </Provider>
    );
  }
}

export default App;
