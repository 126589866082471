import * as React from 'react';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { color, spacing } from '../../assets/styles';

export const APP_WRAPPER_MAX_WIDTH = 1024;
export const GRID_MARGIN = 16;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  max-width: ${APP_WRAPPER_MAX_WIDTH}px;
  padding: 0 ${spacing.small};
  margin: auto;
  box-sizing: border-box;
  background-color: ${color.offWhite};
`;

export default ({ children }: { children: ReactNode }) => <Wrapper>{children}</Wrapper>;
