import { ReactNode } from 'react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { color, breakPoint, lineHeight, spacing, font, weight } from '../../assets/styles';
import { Icon } from '../../common/Icon';

const ItemBody = styled.div`
  display: block;
  flex: 1 0 0;
  padding: ${spacing.small};
  text-decoration: none;
`;

const LinkContentTitle = styled.div`
  font-size: ${font.regularPlus};
  font-weight: ${weight.bold};
  color: ${color.black};
`;

const LinkContentDescription = styled.div`
  font-size: ${font.smallPlus};
  line-height: ${lineHeight.medium};
  color: ${color.black};
  text-transform: none;
  margin-top: ${spacing.xxSmall};
`;

const IconWrapper = styled.div`
  justify-content: center;
  align-items: center;

  @media (max-width: ${breakPoint.mobileMax}) {
    &:last-of-type {
      margin-bottom: ${spacing.small};
    }
    &:first-of-type {
      margin-top: ${spacing.small};
    }
  }

  @media (min-width: ${breakPoint.desktopMin}) {
    flex: 0 0 4.5rem;
    display: flex;
    margin-top: 0;

    &:first-of-type {
      margin-left: ${spacing.smallPlus};
    }
  }
`;

const LinkContentLink = styled(Link)`
  width: 100%;
  min-height: 4.5rem;
  text-decoration: none;
  color: ${color.blue};
  text-align: left;

  @media (max-width: ${breakPoint.mobileMax}) {
    text-align: center;
  }

  @media (min-width: ${breakPoint.desktopMin}) {
    display: flex;
  }
`;

type LinkContentProps = {
  title: string;
  description: string;
  href: string;
  icon: ReactNode;
};

export const LinkContent = ({ title, description, href, icon }: LinkContentProps) => (
  <LinkContentLink to={href}>
    <IconWrapper>{icon}</IconWrapper>
    <ItemBody>
      <LinkContentTitle>{title}</LinkContentTitle>
      <LinkContentDescription>{description}</LinkContentDescription>
    </ItemBody>
    <IconWrapper>
      <Icon name={'arrowRight'} size={'medium'} />
    </IconWrapper>
  </LinkContentLink>
);
