export const styles = {
  color: {
    black: '#1E1E1E',
    blue: '#0C0243',
    charcoal: '#464646',
    darkGray: '#737373',
    gray: '#AFAFAF',
    lightGray: '#D7D7D7',
    white: '#FFFFFF',
    lightBlue: '#494989',
    lighterBlue: '#E4E4EE',
    offWhite: '#fcfbfb',
    ice: '#6294d2',
    heather: '#7E1F89',
    offGrey: '#F0F4F7',
    errorRed: '#9F0000',
    orange: '#f28e00',
    lightOrange: '#f4a433',
    success: '#41BD76',
    nordicBlue: '#0C0243',
    gray200: '#E5E5E5',
    gray600: '#676767',
  },
  font: {
    small: '0.65rem',
    smallPlus: '0.875rem',
    regular: '1rem',
    regularPlus: '1.1rem',
    large: '1.5rem',
    title: '2.5rem',
  },
  lineHeight: {
    medium: '1.43rem',
  },
  weight: {
    regular: '400',
    medium: '500',
    bold: '700',
  },
  spacing: {
    xxSmall: '0.25rem',
    xSmall: '0.5rem',
    smallMinus: '0.75rem',
    small: '1rem',
    smallPlus: '1.25rem',
    medium: '1.5rem',
    large: '2rem',
    largePlus: '2.5rem',
    xLarge: '3rem',
    xxLarge: '4rem',
    xxxLarge: '6rem',
  },
  value: {
    rounding: '4px',
    roundingSmall: '2px',
    footerHeight: '56px', // TODO: use rem?
    paddingTop: '6.25rem',
    margin: '0.4rem',
    marginLeft: '2rem',
    breadCrumbHeight: '2rem',
    width: '1.5rem',
    height: '1rem',
    subContentWidth: '31rem',
    pageContentWidth: '43.5rem',
    appWrapperMaxWidth: '64rem',
  },
  breakPoint: {
    mobileMax: '511px',
    desktopMin: '512px',
    largeScreenMin: '1036px',
  },
};

export type Styles = typeof styles;

export const { breakPoint, color, lineHeight, weight, font, spacing, value } = styles;
