/**
 * Returns the value if truthy (!!value).
 * Throws error otherwise
 * @param {T | null | undefined} value
 * @param {string} msg the message of the error
 * @return {T}
 */
export const verifyTruthy = <T>(value: T | undefined | null, msg: string): T => {
  if (!value) {
    throw new Error(msg);
  }
  return value;
};
