import * as React from 'react';
import { Component, StatelessComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { PnrFormType, UserProfile } from './../exportData.interface';
import { RootState } from '../../../app/state.interface';
import { i18n } from '../../../app/i18n';
import { MainContainer } from '../../../common/layout';
import { TextContainer, WithPadding, AddressTextContainer } from './Containers';
import HeroImage from '../../HeroImage';
import { BoxWithHeading } from '../../../common/Box';
import { Title } from './Texts';
import { ExportForm } from './ExportForm';
import {
  TriggerCreateGdprRequest,
  triggerCreateGdprRequest,
  FetchUserProfile,
  fetchUserProfile,
} from '../ExportDataActions';
import { ExportDataStatus } from '../exportData.interface';
import { Modal } from '../_view/Modal';
import { LightProcessFailureScreen } from '../LightProcessFailureScreen';
import { LightProcessSuccessScreen } from '../LightProcessSuccessScreen';
import { AurinkomatkatBox } from './AurinkomatkatBox';
import { color } from '../../../assets/styles';
import { animateScroll } from 'react-scroll';

const aurinkomatkatBookingPdf = require('./pdfs/Aurinkomatkat_Personal_Data_request_form_fi.pdf');
const finnairPdfEn = require('./pdfs/Finnair_Personal_Data_request_form_en.pdf');
const finnairPdfFi = require('./pdfs/Finnair_Personal_Data_request_form_fi.pdf');

type RouteParams = {
  requestType: PnrFormType;
};

export type ExportFormScreenProps = RouteComponentProps<RouteParams> & {
  locale: string;
  loggedIn: boolean;
  token: string;
  triggerCreateGdprRequest: TriggerCreateGdprRequest;
  exportDataStatus: ExportDataStatus;
  exportDataId: string | undefined;
  fetchUserProfile: FetchUserProfile;
  profile: UserProfile;
};

export interface ExportFormScreenState {
  initialProfile: UserProfile;
}

const flatten = (arr: any) => {
  return arr.reduce(
    (flat: any, toFlatten: any) => flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten),
    []
  );
};

const getErrorFieldNames = (obj: any, name = '') => {
  const errorArr: any = [];
  errorArr.push(
    Object.keys(obj)
      .map((key) => {
        const next = obj[key];
        if (next) {
          if (typeof next === 'string') {
            return name + key;
          }
          // Keep looking
          if (next.map) {
            errorArr.push(
              next
                .map((item: any, index: number) => getErrorFieldNames(item, `${name}${key}[${index}].`))
                .filter((o: any) => o)
            );
          }
        }
        return null;
      })
      .filter((o) => o)
  );
  return flatten(errorArr);
};

const ErrorModal: StatelessComponent<{ resetState: Function }> = ({ resetState }) => (
  <Modal>
    <LightProcessFailureScreen resetState={resetState} />
  </Modal>
);

const SuccessModal: StatelessComponent<{ exportDataId: string; resetState: Function; loggedIn: boolean }> = ({
  exportDataId,
  resetState,
  loggedIn,
}) => (
  <Modal>
    <LightProcessSuccessScreen requestId={exportDataId} resetState={resetState} loggedIn={loggedIn} />
  </Modal>
);

const renderPdfFormLink = (requestType: string, locale: string) => {
  const linkText = i18n('extendedDataRequestForm.here');
  if (requestType === 'aurinkomatkatBooking') {
    if (locale === 'en') {
      return (
        <a target="_blank" href={aurinkomatkatBookingPdf}>
          {linkText}
        </a>
      );
    } else {
      return (
        <a target="_blank" href={aurinkomatkatBookingPdf}>
          {linkText}
        </a>
      );
    }
  } else {
    if (locale === 'en') {
      return (
        <a target="_blank" href={finnairPdfEn}>
          {linkText}
        </a>
      );
    } else {
      return (
        <a target="_blank" href={finnairPdfFi}>
          {linkText}
        </a>
      );
    }
  }
};

const WrapperBox = (props: any) => {
  const { title, children, requestType } = props;
  if (requestType === 'aurinkomatkatBooking') {
    return <AurinkomatkatBox title={title}>{children}</AurinkomatkatBox>;
  } else {
    return <BoxWithHeading title={title}>{children}</BoxWithHeading>;
  }
};

const renderHeroImage = (requestType: string) => {
  if (requestType === 'aurinkomatkatBooking') {
    return <HeroImage title={i18n('exportDataScreen.title')} ingress={i18n('exportDataScreen.amIngress')} />;
  } else {
    return <HeroImage title={i18n('exportDataScreen.title')} ingress={i18n('exportDataScreen.fplusIngress')} />;
  }
};

export class ExportFormScreen extends Component<ExportFormScreenProps, ExportFormScreenState> {
  constructor(props: ExportFormScreenProps) {
    super(props);
    this._handleFormSubmit = this._handleFormSubmit.bind(this);
  }

  // Warning: componentWillMount has been renamed, and is not recommended for use. See https://fb.me/react-async-component-lifecycle-hooks for details.
  // * Move code with side effects to componentDidMount, and set initial state in the constructor.
  UNSAFE_componentWillMount() {
    if (this.props.token) {
      this.props.fetchUserProfile(this.props.token);
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps.profile && (!this.state || !this.state.initialProfile)) {
      this.setState({
        initialProfile: nextProps.profile,
      });
    }
  }

  _getRegisterType() {
    const requestType = this.props.match.params.requestType;
    if (requestType === 'aurinkomatkatBooking') {
      return 'am';
    } else {
      return 'ay';
    }
  }

  _handleFormSubmitFail(errors: any) {
    const errorFields = getErrorFieldNames(errors);

    const elements = errorFields
      .map((fieldName: string) => document.querySelector(`[name="${fieldName}"], #${fieldName}`))
      .filter(Boolean)
      .sort((a: any, b: any) => a.getBoundingClientRect().top - b.getBoundingClientRect().top);

    if (elements.length > 0) {
      const y = elements[0].getBoundingClientRect().top + window.scrollY - 60;
      animateScroll.scrollTo(y);
    }
  }

  _handleFormSubmit(formValues: any) {
    const form = Object.assign({}, formValues);

    // Remove extra fields from the form object
    if (!form.QUESTION_OTHER_TRIPS) {
      delete form.otherFirstName;
      delete form.otherLastName;
      delete form.pnrs;
      delete form.aurinkomatkatBookingIds;
      delete form.otherTravelDetails;
    } else {
      if (form.pnrs) {
        form.pnrs = form.pnrs.split(',').map((a: string) => a.trim());
      }
      if (form.aurinkomatkatBookingIds) {
        form.aurinkomatkatBookingIds = form.aurinkomatkatBookingIds.split(',').map((a: string) => a.trim());
      }
    }
    if (!form.QUESTION_PURCHASES) {
      delete form.questionPurchaseName;
      delete form.questionPurchaseDetails;
      if (!form.QUESTION_FINNAIR_PLUS) {
        delete form.finnairPlusId;
      }
    }
    if (!form.QUESTION_FEEDBACK) {
      delete form.questionFeedbackDetails;
    }
    if (!form.QUESTION_SURVEY) {
      delete form.questionSurveyDetails;
    }
    if (form.otherTravelDetails) {
      form.otherTravelDetails = form.otherTravelDetails.join('\n');
    }
    if (form.questionSurveyDetails) {
      form.questionSurveyDetails = form.questionSurveyDetails.join('\n');
    }

    if (this.props.locale.indexOf('_') > 0) {
      form.language = this.props.locale.substring(0, this.props.locale.indexOf('_'));
    } else {
      form.language = this.props.locale;
    }

    if (this.props.token && !form.finnairPlusId && this.state && this.state.initialProfile) {
      form.finnairPlusId = this.state.initialProfile.memberNumber;
    }

    const keys = Object.keys(form);
    form.checkedQuestions = keys
      .filter((key) => key.startsWith('QUESTION_') && form[key])
      .map((key) => {
        return key;
      });
    keys.filter((key) => key.startsWith('QUESTION_')).forEach((key) => delete form[key]);

    keys
      .filter((key) => 'string' === typeof form[key])
      .filter((key) => form[key].trim().length === 0)
      .forEach((key) => delete form[key]);

    form.registerType = this._getRegisterType();

    this.props.triggerCreateGdprRequest(form, this.props.token);
  }

  _resetStateAndRedirect = () => {
    this.props.history.push('/');
  };

  render() {
    const initialValues: any = {
      otherTravelDetails: [''],
      questionSurveyDetails: [''],
    };
    if (this.state && this.state.initialProfile) {
      initialValues.firstName = this.state.initialProfile.firstname;
      initialValues.lastName = this.state.initialProfile.lastname;
      initialValues.finnairPlusId = this.state.initialProfile.memberNumber;
      if (this.state.initialProfile.mobilePhone) {
        initialValues.phoneNumber = `+${this.state.initialProfile.mobilePhone}`;
      }
      if (this.state.initialProfile.addresses) {
        const address = this.state.initialProfile.addresses[0];
        let addressFields = [];
        if (address.streetAddress) {
          addressFields.push(address.streetAddress);
        }
        if (address.postalCode) {
          addressFields.push(address.postalCode);
        }
        if (address.city) {
          addressFields.push(address.city);
        }
        if (address.country) {
          addressFields.push(address.country);
        }
        initialValues.address = addressFields.join(' ');
      }
      initialValues.email = this.state.initialProfile.email;
    }
    const requestType = this.props.match.params.requestType;
    const { exportDataStatus, exportDataId } = this.props;
    const titleColor = requestType === 'aurinkomatkatBooking' ? color.orange : color.blue;
    return (
      <MainContainer>
        {exportDataStatus === ExportDataStatus.FAILED && <ErrorModal resetState={this._resetStateAndRedirect} />}
        {exportDataStatus === ExportDataStatus.SUCCESS && !!exportDataId && (
          <SuccessModal
            exportDataId={exportDataId}
            resetState={this._resetStateAndRedirect}
            loggedIn={this.props.loggedIn}
          />
        )}

        {renderHeroImage(requestType)}
        <WrapperBox title={i18n('extendedDataRequestForm.title')} requestType={requestType}>
          {(!this.props.loggedIn || (this.props.loggedIn && this.state && this.state.initialProfile)) && (
            <ExportForm
              loggedIn={this.props.loggedIn}
              locale={this.props.locale}
              titleColor={titleColor}
              requestType={requestType}
              form="GdprForm"
              onSubmit={this._handleFormSubmit}
              onSubmitFail={this._handleFormSubmitFail}
              initialValues={initialValues}
              submitClicked={exportDataStatus === ExportDataStatus.PROCESSING}
              profile={this.props.profile}
            />
          )}
        </WrapperBox>
      </MainContainer>
    );
  }
}

export default withRouter(
  connect(
    (state: RootState) => ({
      locale: state.language.currentLocale,
      loggedIn: !!state.login.token,
      token: state.login.token,
      exportDataStatus: state.exportData.status,
      exportDataId: state.exportData.requestId,
      profile: state.exportData.profile,
    }),
    {
      triggerCreateGdprRequest: triggerCreateGdprRequest,
      fetchUserProfile: fetchUserProfile,
    }
  )(ExportFormScreen)
);
