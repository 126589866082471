import * as React from 'react';
import styled from 'styled-components';
import { Field, FieldArray } from 'redux-form';
import { ReduxFormExpand } from '../../../../common/Expand/ReduxFormExpand';
import { titleArea, openIcon, closedIcon } from './expandParts';
import { FormInputSection, TextContainer } from '../Containers';
import { TextInput } from '../../../../common/reduxForms';
import * as validation from '../validations';
import { Note } from '../../../../common/Note';
import { Col, Row } from '../../../../common/grid';
import { AddItemButton } from '../AddItemButton';
import { i18n } from '../../../../app/i18n';

export type OtherTripsProps = {
  requestType: string;
};

const ColDivider = styled.div`
  flex: 1 0 0 auto;
  width: 10px;
`;

const renderDynamicFields = (props: any) => {
  const { fields } = props;
  return (
    <div>
      {fields.map((member: string, index: number) => {
        const name = `otherTravelDetails[${index}]`;
        return (
          <Field
            key={index}
            name={name}
            component={TextInput}
            label={i18n('exportDataScreen.otherTripsTravelDetails')}
            hint={i18n('exportDataScreen.otherTripsTravelDetailsHint')}
            validate={[]}
          />
        );
      })}
      <AddItemButton title={i18n('exportDataScreen.otherTripsTravelDetailsAdd')} onClick={() => fields.push('')} />
    </div>
  );
};

const renderOtherTripsFields = (requestType: string) => (
  <FormInputSection>
    <Row>
      <Col>
        <Field
          name="otherFirstName"
          component={TextInput}
          label={i18n('exportDataScreen.otherTripsFirstName')}
          validate={[validation.required, validation.notNumbers]}
        />
      </Col>
      <ColDivider />
      <Col>
        <Field
          name="otherLastName"
          component={TextInput}
          label={i18n('exportDataScreen.otherTripsLastName')}
          validate={[validation.required, validation.notNumbers]}
        />
      </Col>
    </Row>
    {requestType === 'finnairPnr' && (
      <Field
        name="pnrs"
        component={TextInput}
        label={i18n('exportDataScreen.otherTripsBookingReferences')}
        hint={i18n('exportDataScreen.otherTripsPnrsHint')}
        validate={[validation.commaSeparatedPNRs]}
      />
    )}
    {requestType === 'aurinkomatkatBooking' && (
      <Field
        name="aurinkomatkatBookingIds"
        component={TextInput}
        label={i18n('exportDataScreen.otherTripsBookingReferences')}
        hint={i18n('exportDataScreen.otherTripsAmBookingIdsHint')}
        validate={[validation.commaSeparatedAurinkomatkatBookingIds]}
      />
    )}
    {requestType !== 'aurinkomatkatBooking' && (
      <Note title={i18n('exportDataScreen.note')} text={i18n('exportDataScreen.otherTripsBookingReferencesHint')} />
    )}
    <TextContainer>{i18n('exportDataScreen.otherTripsParagraph')}</TextContainer>
    <FieldArray name="otherTravelDetails" component={renderDynamicFields} />
  </FormInputSection>
);

const FinnairPlusExpand: React.StatelessComponent<OtherTripsProps> = (props: OtherTripsProps) => {
  const requestType = props.requestType;
  let tripsWithRegisterName = i18n('exportDataScreen.otherTripsWithFinnair');
  if (requestType === 'aurinkomatkatBooking') {
    tripsWithRegisterName = i18n('exportDataScreen.otherTripsWithAurinkomatkat');
  }
  return (
    <ReduxFormExpand
      openIcon={openIcon()}
      closedIcon={closedIcon()}
      title={titleArea(tripsWithRegisterName)}
      content={renderOtherTripsFields(requestType)}
      {...props}
    />
  );
};

export const OtherTripsFields = (props: OtherTripsProps) => {
  const { requestType } = props;
  return (
    <Field
      name="QUESTION_OTHER_TRIPS"
      component={FinnairPlusExpand}
      {...{
        requestType,
      }}
    />
  );
};
