import { ParsedUrlQuery } from 'querystring';
import * as queryString from 'querystring';
import { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { getFirstValue } from '../../utils/first-value';
import { LOGIN_TOKEN_STORAGE_KEY } from './login.interface';
import { LoginActions } from './LoginActions';
import { safeGetFromStorage, safeSetToStorage } from './utils';

export type LoginWrapperProps = RouteComponentProps<void> & {
  children: any;
  setLoginToken: typeof LoginActions.storeToken;
};

const parseHashString = (hashString = ''): ParsedUrlQuery => queryString.parse(hashString.replace(/^#/, ''));

class LoginWrapper extends Component<LoginWrapperProps> {
  //   @TODO Warning: componentWillMount has been renamed, and is not recommended for use. See https://fb.me/react-async-component-lifecycle-hooks for details.
  //   * Move code with side effects to componentDidMount, and set initial state in the constructor.
  UNSAFE_componentWillMount() {
    const params = parseHashString(this.props.location.hash);
    const accessTokenFromParams = getFirstValue(params.access_token);
    if (accessTokenFromParams) {
      this._storeLoginToken(accessTokenFromParams);
      this.props.history.replace(this.props.location.pathname);
    } else {
      this._possiblySetLoginTokenFromSessionStorage();
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }

  private _possiblySetLoginTokenFromSessionStorage() {
    const storedValue = safeGetFromStorage(LOGIN_TOKEN_STORAGE_KEY, window.sessionStorage);
    if (!!storedValue) {
      this.props.setLoginToken(storedValue);
    }
  }

  private _storeLoginToken(accessToken: string) {
    this.props.setLoginToken(accessToken);
    safeSetToStorage(LOGIN_TOKEN_STORAGE_KEY, accessToken, window.sessionStorage);
  }
}

const mapDispatchToProps = {
  setLoginToken: LoginActions.storeToken,
};
export default connect(() => ({}), mapDispatchToProps)(withRouter(LoginWrapper));
